import { ErrorAlert } from '@/components/blockalerts/ErrorAlert';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';
import { animate, MotionValue, Reorder, useDragControls, useMotionValue } from 'framer-motion';
import { Ban, Check, Circle, Edit, FileUp, GripHorizontal, Trash2 } from 'lucide-react';
import React, { useEffect } from 'react';

export const SettingsHelpEntry = ({
    editingID, 
    item,
    setEditingID,
    submitItemEdit,
    editClicked,
    deleteClicked,
    cancelEditClicked,
    previewClicked,
}) => {
    const fileRef = React.useRef(null);
    const [shouldChangeFile, setShouldChangeFile] = React.useState(item.url ? false : true);
    const [file, setFile] = React.useState(item?.url);
    const [editedTitle, setEditedTitle] = React.useState(item.title || '');
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [isBeingDeleted, setIsBeingDeleted] = React.useState(false);

    const y = useMotionValue(0)
    const dragControls = useDragControls()
    const boxShadow = useRaisedShadow(y);

    useEffect(resetState,[item, editingID === item.id])

    const isBeingEdited = editingID === item.id;
    const siblingBeingEdited = editingID && editingID !== item.id;

    function prepareSubmitEdit() {
        // Validate name
        if (!editedTitle || editedTitle.trim()?.length === 0) {
            setError('Name cannot be empty');
            return;
        }
        // Validate file
        if (shouldChangeFile && !file) {
            setError('File cannot be empty');
            return;
        } else console.log('file is good',  file);
        console.log(file, fileRef);
        setLoading(true);
        submitItemEdit(item, editedTitle, fileRef, () => setLoading(false));
    }
    function prepareDeleteClicked() {
        setIsBeingDeleted(true);
        deleteClicked(item, () => setIsBeingDeleted(false))
    }
    function prepareCancelEdit() {
        cancelEditClicked(item)
        resetState()
    }
    function resetState() {
        setShouldChangeFile(item.url ? false : true)
        setEditedTitle(item.title)
        setFile(item.url)
        setError(null)
        setLoading(false)
    }
    function handleFileChange(event) {
        setShouldChangeFile(true)
        if (!event) {
            setFile(null);
            fileRef.current.value = "";
            return;
        }
        const file = event.target?.files?.[0];
        const url = URL.createObjectURL(file);
        setFile(url);

    }
    // if (isBeingEdited) {
    //     console.log('file:',file, !!file);
    //     console.log('fileRef:', fileRef.current);
    // }
    
    return (
        <Reorder.Item
            layout='position'
            layoutScroll
            dragControls={dragControls}
            dragListener={false}
            value={item}
            style={{ boxShadow, y }}
            className={cn(
                'select-none relative mt-2 !overflow-hidden',
                siblingBeingEdited && 'opacity-40 pointer-events-none',
                )}>
            <div  className="flex items-center justify-between p-4 border rounded-md bg-background overflow-hidden h-18">
                {isBeingEdited 
                    ? (
                        <div className='flex-grow max-w-[30rem]'>
                            {/* <Label  className='flex text-xs font-medium gap-2 items-center'>
                                File Name
                            </Label> */}
                            <ErrorAlert message={error} title='Error' className='mb-4'/>
                            <Input 
                                className='w-full'
                                value={editedTitle}
                                onChange={(e) => setEditedTitle(e.target.value)}
                            />
                            {!!file && (
                                <div className='mt-4 w-full'>
                                    <UploadedFile 
                                        fileUrl={file}
                                        fileInput={fileRef}
                                        handleDelete={() => handleFileChange(null)}
                                        showPreview={() => previewClicked({name: editedTitle, url: file})}
                                    />
                                </div>
                            )}
                            <div className={cn("mt-4", !!file && 'invisible bg-red-400 absolute')}>
                                <FileUploadArea 
                                    ref={fileRef}
                                    onChange={handleFileChange}      
                                    id={`file-upload-${item.id}`}
                                />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <span className='font-medium'>
                                {item.title}
                            </span>
                            <span className="text-sm block hover:underline underline-offset-2 hover:cursor-pointer text-muted-foreground" onClick={() => previewClicked(item)}>
                                Show preview
                            </span>
                        </div>
                    )
                }
                <div  className="ml-auto flex gap-2 items-center self-start">
                    {isBeingEdited && (
                        <>
                            <Button size='icon' variant='success' onClick={prepareSubmitEdit}>
                                { loading 
                                    ? <div className="loading"></div>
                                    : <Check/>
                                }
                            </Button>
                            <Button disabled={loading} size='icon' variant='destructive' onClick={prepareCancelEdit}>
                                <Ban/>
                            </Button>
                        </>
                    )}
                    {!isBeingEdited && (
                        <>
                            <Button variant='ghost' size='icon' onClick={() => editClicked(item)}>
                                <Edit/>
                            </Button>
                            <Separator orientation='vertical' className='h-10 mx-2'/>
                            <Button variant='destructive-ghost' size='icon' onClick={prepareDeleteClicked}>
                                { isBeingDeleted 
                                    ? <div className="loading"></div>
                                    : <Trash2/>
                                }
                            </Button>
                            <GripHorizontal
                                onPointerDown={(e) => dragControls.start(e)}
                                className='ml-10 text-muted-foreground hover:cursor-grab hover:active:cursor-grabbing'/>
                        </>
                    )}
                </div>
            </div>
        </Reorder.Item>
    )
};




type FileUploadAreaProps = {
    id: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement> | null) => void;
};

const FileUploadArea = React.forwardRef<HTMLInputElement, FileUploadAreaProps>(({id, onChange,  ...props}, ref) => {
    return (
        <label
            htmlFor={id}
            className='block relative border-border border-[3px] rounded-md border-dashed w-full min-h-32 py-6 hover:cursor-pointer'
            >
        <div className='flex flex-col items-center pointer-events-none px-6'>
            <FilePlaceholder/>
            <FileUploadHeader text='Drag and drop your file here or click to browse' />
            <FileUploadDescription text='The file submitted here will be linked with this FAQ entry'/>
            <div className="border-2 py-2 px-4 font-medium text-sm rounded-md">
                Browse Files
            </div>
        </div>
        <input
            id={id}
            ref={ref}
            type='file'
            className='block center-abs w-full h-full opacity-0 hover:cursor-pointer'
            onChange={onChange}
            {...props}
            />
    </label>
    )
});
FileUploadArea.displayName = 'FileUploadArea';


const FileUploadHeader = ({text}) => (
    <p className='font-semibold text-lg '>{text}</p>
)

const FileUploadDescription = ({text}) => (
    <p className='text-muted-foreground text-center font-light mb-4'>{text}</p>
)

const FilePlaceholder = () => (
    <div className=' relative z-10 rounded-xl border-[6px] h-20 w-20 shadow-inner mb-6 box-content'>
        <Circle className=' fill-neutral/50  stroke-none w-[105%] h-[105%] center-abs'/>
        <FileUp className='stroke-neutral filter brightness-50 center-abs  w-1/2 h-1/2'/>
    </div>   
)






const inactiveShadow = "0px 0px 0px rgba(0,0,0,0.8)";

export function useRaisedShadow(value: MotionValue<number>) {
  const boxShadow = useMotionValue(inactiveShadow);

  useEffect(() => {
    let isActive = false;
    value.onChange((latest) => {
      const wasActive = isActive;
      if (latest !== 0) {
        isActive = true;
        if (isActive !== wasActive) {
          animate(boxShadow, "5px 5px 10px rgba(0,0,0,0.3)");
        }
      } else {
        isActive = false;
        if (isActive !== wasActive) {
          animate(boxShadow, inactiveShadow);
        }
      }
    });
  }, [value, boxShadow]);

  return boxShadow;
}

const UploadedFile = ({fileUrl, fileInput, handleDelete, showPreview}) => {
    const inputFilenName = fileInput.current?.files?.[0]?.name
    const fileName = inputFilenName || fileUrl?.split?.('/')?.pop()

    return fileUrl && (
        <div className={cn('border py-3 px-4 rounded-xl flex flex-col justify-center')}>
            <div className="flex gap-4 ">
                <div className="h-full flex flex-col items-center justify-center">
                    <div className='bg-primary-shad/10 rounded-full p-2 h-fit w-fit'>
                        <FileUp className='stroke-primary-shad filter brightness-90 w-6 h-6'/>
                    </div>
                </div>
                <div className='h-full flex flex-col justify-between'>
                    <p className='font-semibold text-sm'>{fileName}</p>
                    <div className="flex gap-2 items-center hover:cursor-pointer" onClick={showPreview}>
                        Show Preview 
                    </div>
                </div>
                <Button onClick={handleDelete} className='ml-auto mb-auto p-1' variant={'destructive'} size={'iconsm'}>
                    <Trash2 className='w-6 h-6'/>
                </Button>
            </div>
        </div>
    )
}