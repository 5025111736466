import { useCreateFAQ } from '@/api/microservice/createFAQ';
import { useDeleteFAQ } from '@/api/microservice/deleteFAQ';
import useListFAQs from '@/api/microservice/listFAQs';
import { useModFAQ } from '@/api/microservice/modFAQ';
import { useReorderFAQs } from '@/api/microservice/reorderFAQs';
import { PageHeader } from '@/components/misc/PageHeader';
import { PreviewDocumentDialog } from '@/components/misc/PreviewDocumentDialog';
import { ScrollArea } from '@/components/ui/scroll-area';
import { HelpSettingsActionBar } from '@/routes/Main/Admin/HelpSettings/components/HelpSettingsActionBar';
import { HelpSettingsEntriesSkeleton } from '@/routes/Main/Admin/HelpSettings/components/HelpSettingsEntriesSkeleton';
import { SettingsHelpEntry } from '@/routes/Main/Admin/HelpSettings/components/SettingsHelpEntry';
import { SettingsManagementGuide } from '@/routes/Main/Admin/HelpSettings/components/SettingsManagementGuide';
import { useQueryResultToasts } from '@/wrappers/toasts';
import { AnimatePresence, Reorder } from 'framer-motion';
import React, { useEffect } from 'react';

const tempItem = { id: 'temp', title: ''};
export const HelpSettings = ({ ...props}) => {
    const [items, setItems] = React.useState([]);
    const [creating, setCreating] = React.useState(false);
    const [previewOpen, setPreviewOpen] = React.useState(false);
    const [previewData, setPreviewData] = React.useState(null);
    const [editingID, setEditingID] = React.useState(null);
    const [hasChangedOrder, setHasChangedOrder] = React.useState(false);
    const queryResultToasts = useQueryResultToasts()

    const FAQsQuery = useListFAQs()
    const deleteMutation = useDeleteFAQ(queryResultToasts('FAQ Deleted', 'Failed to delete FAQ'))
    const editMutation = useModFAQ(queryResultToasts('FAQ Edited', 'Failed to edit FAQ', handleEntryCreationSuccess))
    const createMutation = useCreateFAQ(queryResultToasts('FAQ Created', 'Failed to create FAQ', handleEntryCreationSuccess, handleCreationFail))
    const reorderMutation = useReorderFAQs(queryResultToasts('FAQ Reordered', 'Failed to reorder FAQ',handleReorderMutationSuccess))

    useEffect(() => {
        if (FAQsQuery.data) {
            const newArr = items
            // Handle any deletions
            for (let i = newArr.length - 1; i >= 0; i--) {
                const newDataHasItem = 
                    FAQsQuery.data.find((queryItem) => queryItem.id === newArr[i].id)
                if (!newDataHasItem) {
                    newArr.splice(i, 1)
                } else {
                    // Handle any updates
                    // console.log('old:',newArr[i]);
                    // console.log('new:',newDataHasItem,'\n\n');
                    newArr[i] = {
                        ...newArr[i], ...newDataHasItem
                    } 
                }
            }
            // Handle any additions
            for (const queryItem of FAQsQuery.data) {
                const existingItem = newArr.find((item) => item.id === queryItem.id)
                if (!existingItem) {
                    newArr.push(queryItem)
                }
            }
            setItems([...newArr])
        }
        
    },[FAQsQuery.data])

    function submitItemEdit(item, title, fileRef, onEditFailedCallback) {
        const changeFile = !!fileRef?.current?.files?.[0]
        const changeTitle = item.title !== title
        editMutation.mutate({
            id: item.id, 
            title, 
            file: fileRef?.current?.files?.[0],
            changeFile,
            changeTitle
        }, {onError: onEditFailedCallback})
    }
    function deleteClicked(entry, onDeleteFailedCallback) {
        deleteMutation.mutate({id: entry.id}, {onError: onDeleteFailedCallback})
    }
    function cancelEditClicked(entry) {
        setEditingID(null);
    }
    function editClicked(entry) {
        setEditingID(entry.id);
    }
    function previewClicked(entry) {
        setPreviewOpen(true);
        setPreviewData({title: entry.title, documents: [{uri: entry.url}]});
    }
    function enterEntryCreation() {
        setCreating(true);
        setEditingID('temp')
    }
    function handleEntryCreation(item, title, fileRef, onCreationFailedCallback) {
        createMutation.mutate({
            title, 
            file: fileRef.current.files[0]
        }, {onError: onCreationFailedCallback})
    }
    function cancelEntryCreation() {
        setCreating(false);
        setEditingID(null);
    }
    function handleEntryCreationSuccess() {
        console.log('in success');
        setCreating(false);
        setEditingID(null);
    }
    function handleCreationFail() {
        setEditingID(null);
        setCreating(false);
    }
    function handleReorder(newItemsOrder) {
        console.log('reordering');
        if (!hasChangedOrder) setHasChangedOrder(true);
        setItems(newItemsOrder)
    }
    function handleReorderSubmit() {
        reorderMutation.mutate({ordering: items.map((item) => item.id)})
    }
    function handleReorderMutationSuccess() {
        setHasChangedOrder(false);
    }

    return (
        <div className='h-page flex flex-col max-w-[64rem]'>
            <PageHeader title="Manage Help Page Content" />
            <div className="flex justify-between w-full items-start">
                <SettingsManagementGuide/>
                <HelpSettingsActionBar 
                    {...{handleReorderSubmit, enterEntryCreation, hasChangedOrder}}
                />
            </div>
            <ScrollArea className='mt-2 flex-grow' hideScrollbar>
                <Reorder.Group axis='y' values={items} onReorder={handleReorder}>
                    {creating && (
                        <SettingsHelpEntry
                            {...{
                                editingID, 
                                item: tempItem,
                                setEditingID,
                                submitItemEdit: handleEntryCreation,
                                editClicked: null,
                                deleteClicked: null,
                                cancelEditClicked: cancelEntryCreation,
                                previewClicked,
                            }}
                            />
                    )}
                    { FAQsQuery.isPending ? (
                        <HelpSettingsEntriesSkeleton/>
                    ) : (
                        <AnimatePresence>
                            { items.map((item) => (
                                <SettingsHelpEntry
                                    key={item.id} 
                                    {...{
                                        editingID, 
                                        item,
                                        setEditingID,
                                        submitItemEdit,
                                        editClicked,
                                        deleteClicked: deleteClicked,
                                        cancelEditClicked,
                                        previewClicked,
                                    }}
                                    
                                    />
                            ))}
                        </AnimatePresence>
                    )}
                </Reorder.Group>
            </ScrollArea>
            <PreviewDocumentDialog
                dialogTitle={`Preview for '${previewData?.title}'`}
                dialogProps={{
                    open: previewOpen,
                    onOpenChange: setPreviewOpen,
                }}
                documents={previewData?.documents}
            />
        </div>
    );
};
