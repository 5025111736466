import { StatusCell } from "@/components/tables/cells/StatusCell";
import { maxDateFilterFn } from "@/components/tables/helpers/filterFns";
import { Button } from "@/components/ui/button";
import { nullNum } from "@/util/miscUtils";
import { getDateString } from "@/util/textUtils";
import { createColumnHelper, filterFns } from "@tanstack/react-table";
import { ExternalLinkIcon, Flag } from "lucide-react";
import { Link } from "react-router-dom";

const columnHelper = createColumnHelper();

const stringFilter = filterFns.includesString;

export const agenciesColumnHelper = [
    columnHelper.accessor((row) => row.id, {
        id: "ID",
        size: 10,
        filterFn: stringFilter,
        meta: {
            hiddenCol: true,
            hiddenFilter: true,
            hideExport: true
        },
        cell: (info) => <span>{info.getValue()}</span>,
        header: (info) => <span>ID</span>,
    }),
    columnHelper.accessor((row) => row?.branchName, {
        id: "Branch",
        size: 100,
        filterFn: stringFilter,
        cell: (info) => <span>{info.getValue()}</span>,
        header: (info) => <span>Branch</span>,
    }),
    columnHelper.accessor((row) => row.Name, {
        id: "Name",
        size: 100,
        filterFn: stringFilter,
        cell: (info) => <span className="whitespace-nowrap w-fit">{info.getValue()}</span>,
        header: (info) => <span className="">Name</span>,
    }),
    columnHelper.accessor((row) => row.displayName, {
        id: "Display Name",
        size: 100,
        filterFn: stringFilter,
        cell: (info) => <span className="whitespace-nowrap">{info.getValue()}</span>,
        header: (info) => <span className="whitespace-nowrap ">Display Name</span>,
    }),
    columnHelper.display({
        id: "Contact",
        size: 100,
        meta: {},
        cell: ({row: {original}}) => (<ContactCell {...{original}}/>),
        header: (info) => <span>Contact</span>,
    }),
    columnHelper.accessor((row) => row.contactName, {
        id: "Contact Name",
        size: 100,
        meta: {
            hiddenCol: true,
        },
        filterFn: stringFilter,
        cell: (info) => <span className="whitespace-nowrap">{info.getValue()}</span>,
        header: (info) => <span className="whitespace-nowrap ">Contact Name</span>,
    }),
    columnHelper.accessor((row) => row.contactEmail, {
        id: "Contact Email",
        size: 100,
        meta: {
            hiddenCol: true,
        },
        filterFn: stringFilter,
        cell: (info) => <span className="whitespace-nowrap">{info.getValue()}</span>,
        header: (info) => <span className="whitespace-nowrap ">Contact Email</span>,
    }),
    // columnHelper.accessor((row) => row.displayName, {
    //     id: "Display Name",
    //     size: 100,
    //     filterFn: stringFilter,
    //     cell: (info) => <span>{info.getValue()}</span>,
    //     header: (info) => <span>Name</span>,
    // }),
    columnHelper.accessor((row) => row?.city, {
        id: "City",
        size: 100,
        filterFn: stringFilter,
        cell: ({getValue}) => <span className="whitespace-nowrap">{getValue()}</span>,
        header: (info) => <span>City</span>,
    }),
    columnHelper.accessor((row) => row?.isActive, {
        id: "Status",
        size: 100,
        filterFn: stringFilter,
        sortUndefined: 'last',
        cell: ({getValue}) => <StatusCell isActive={getValue()}/>,
        header: (info) => <span>Status</span>,
    }),
    columnHelper.display({
        id: "Actions",
        size: 100,
        meta: {
            hideExport: true
        },
        cell: ({ row, table }) => <Actions {...{ row, table }} />,
        header: (info) => <span></span>,
    }),
    columnHelper.accessor((row) => nullNum(row?.flaggedTimestamp) * 1000, {
        id: "Flagged",
        size: 10,
        filterFn: maxDateFilterFn,
        cell: ({row: {original}, table}) => <Flagged {...{row: original, table}}/>,
        header: (info) => <span className="whitespace-nowrap">Last Flagged</span>,
    }),
]
    
const Actions = ({row, table}) => {
    const {goToAgency, branchID} = table.options.meta || {}
    return (
        <div className="flex gap-2 justify-end ">
            <Link to={'/agency/' + (branchID || row.original.branchID) + '/' + row.original.id} draggable="false" >
                <Button  variant="outline" size={'sm'} className="flex gap-2 items-center text-end">
                    Go to agency profile <ExternalLinkIcon className="w-4"/>
                </Button>
            </Link>
            {/* <Link to={'/agency/' + row.original.branchID + row.original.id + '/mailboxes'} draggable="false" >
                <Button  variant="outline" size={'sm'} className="flex gap-2 items-center text-end">
                    See inboxes in agency <ExternalLinkIcon className="w-4"/>
                </Button>
            </Link> */}

        </div>
    )
}

const Flagged = ({row, table}) => {
    const {flagAgency} = table.options.meta || {}
    return (
        <div className="flex items-center gap-1 w-fit whitespace-nowrap">
        {row.flaggedTimestamp ? (
            <span className="text-xs">{getDateString(row.flaggedTimestamp * 1000)}</span>
        ) : (
            <span className="text-muted-foreground text-xs">Unflagged</span>
        )}
            <Button onClick={() => flagAgency(row)} variant="outline" size="iconsm" className="" >
            <Flag className="fill-red-500 w-4 h-4 "/></Button>
        </div>
    )
}

// const Actions = ({row, table})  => {
//     const {modBranch, goToBranch} = table.options.meta || {}
//     return (
//         <div className="float-right">
//             <DropdownMenu >
//                 <DropdownMenuTrigger asChild>
//                     <Button variant="outline" size={'iconsm'}><EllipsisIcon className="w-5"/></Button>
//                 </DropdownMenuTrigger>
//                 <DropdownMenuContent  className="w-56">
//                     <DropdownMenuLabel>Actions</DropdownMenuLabel>
//                     <DropdownMenuSeparator />
//                     <DropdownMenuGroup>
//                         <DropdownMenuItem onClick={() => modBranch(row?.original)}>
//                             <EditIcon className="mr-2 h-5 w-5"/>
//                             <span>Edit Branch</span>
//                         </DropdownMenuItem>
//                         <DropdownMenuItem onClick={() => goToBranch(row?.original)}>
//                             <UserMinus className="mr-2 h-5 w-5"/>
//                             <span>See Branch</span>
//                         </DropdownMenuItem>
//                     </DropdownMenuGroup>
//                 </DropdownMenuContent>
//             </DropdownMenu>
//         </div>
//     )
// }

const ContactCell = ({original}) => {
    const {contactName, contactEmail} = original
    return (
        <div className="flex flex-col gap-1">
            <span className="whitespace-nowrap font-medium leading-none">{contactName}</span>
            <span className="whitespace-nowrap leading-none text-xs">{contactEmail}</span>
        </div>
    )
}