import { getAgencyQueryOptions } from "@/api/agencies/getAgency";
import { getAgencyEnclosingBranch, getAgencyEnclosingBranchQueryOptions } from "@/api/agencies/getAgencyEnclosingBranch";
import { postPromise } from "@/api/promises";
import { ROUTE_IDS } from "@/api/routeIDs";
import { queryClient } from "@/App";
import { composeFormData } from "@/util/apiUtils";
import { MILLISEC } from "@/util/constants";
import { condenseDuplicateNumbers, filterInactiveNumbers } from "@/util/queryUtils";
import {
    UseQueryResult,
    queryOptions,
    useQueries,
    useQuery,
} from "@tanstack/react-query";
import { clone, cloneDeep } from "lodash";
import { useCallback, useRef } from "react";


export const getNumbersInAgency = async ({ queryKey,...props }) => {
    const [_key, { agencyid }] = queryKey;

    const agencyDataPromise = queryClient.ensureQueryData(getAgencyQueryOptions(agencyid))

    const numbersDataPromise = postPromise(
        import.meta.env.VITE_API_BASE_URL,
        composeFormData({ agencyid }, ROUTE_IDS.GET_NUMBERS_IN_AGENCY)
    );
    const [agencyData, numbersData] =  await Promise.all([agencyDataPromise, numbersDataPromise])
    // console.log('agencydata', agencyData.isActive);
    const enrichedNumbers = numbersData.map((num) => { 
        const ageRangeField = num?.clientdata?.ageRange
        const newAgeRange = ageRangeField?.[0] === '46 to 44' ? ['26 to 44'] : ageRangeField
        // console.log("ageRangeField", ageRangeField)
        return ({
        ...num,
        oldTotal: num.totalMsgs,
        totalMsgs: Number(num.inbox) + Number(num.deleted),
        clientdata: { 
            ...num?.clientdata,
            ageRange: newAgeRange
        },
        // oldRead: num.readMsgs,
        // readMsgs: Math.min(Number(num.readMsgs) + Number(num.deleted), Number(num.inbox) + Number(num.deleted)),
        agencyActive: agencyData.isActive,
        branchname: agencyData.branchName,
        branchid: agencyData.branchID,
        agencyid: agencyData.id,
        agencyname: agencyData.Name,
        agencydata: agencyData
    })})
    // console.log("enrichedNumbers", enrichedNumbers)
    const dedupedNumbers = condenseDuplicateNumbers(enrichedNumbers)
    const inactiveRemoved = filterInactiveNumbers(dedupedNumbers)
    return inactiveRemoved;
};

export default function useNumbersInAgency(agencyid, config = {}) {
    const query = useQuery({
        ...getNumbersInAgencyQueryOptions(agencyid),
        ...config,
    });
    return query;
}

export function useNumbersInAgencies(agencies, enabled=true) {
    const fallbackDataRef = useRef([]);
    // console.log('a',agencies);
    const queries = useQueries<any[], UseQueryResult<unknown, Error>[]>({
        queries: enabled && !!agencies
            ? agencies.map((a) => getNumbersInAgencyQueryOptions(a.id))
            : fallbackDataRef.current,
        combine: useCallback((queries) => {
            const isPending = !enabled || queries.some((q) => q.isPending);
            if (isPending || !(queries.length === agencies.length))
                return { data: null, isPending };
            const data = queries
                .map((q, idx) => {
                    const transformedNumbers = q.data.map((num) => {
                        const ret = {
                            ciscoFirstName: num.firstName,
                            ciscoLastName: num.lastName,
                            ...num
                        }
                        ret.oldTotal = ret.totalMsgs
                        ret.totalMsgs = Number(ret.inbox) + Number(ret.deleted)
                        // Math.min is used because depending on C's logic, we might go over the total quantity of messages
                        ret.listened = Math.min(Number(ret.listened) + ret.deleted, ret.totalMsgs)
                        const sourceAgency = agencies[idx]
                        ret.agencyid = sourceAgency.id;
                        ret.agencyname = sourceAgency.Name;
                        (ret.branchname = sourceAgency.branchName),
                        (ret.branchid = sourceAgency.branchID);

                        return ret
                    })
                    return transformedNumbers;
                })
                ?.flat?.();
            const dedupedNumbers = condenseDuplicateNumbers(data)
            return {
                data: dedupedNumbers,
                isPending,
            };
        }, [agencies, enabled]),
    });
    return queries;
}

export const getNumbersInAgencyQueryOptions = (agencyid) =>
    queryOptions({
        staleTime: MILLISEC.MIN_14,
        enabled: agencyid != null,
        gcTime: MILLISEC.MIN_15,
        queryKey: [ROUTE_IDS.GET_NUMBERS_IN_AGENCY, { agencyid }],
        queryFn: getNumbersInAgency,
    });
