import { getAgenciesInBranchQueryOptions } from '@/api/agencies/getAgenciesInBranch';
import { postPromise } from '@/api/promises';
import { ROUTE_IDS } from '@/api/routeIDs';
import { composeFormData } from '@/util/apiUtils';
import { MILLISEC } from '@/util/constants';
import { queryOptions, useQueries, useQuery, UseQueryResult } from '@tanstack/react-query';
import { useCallback } from 'react';

export const getAgency = async ({queryKey}) => {
    const [_, {agencyID}] = queryKey;
    try {
        const agencyData = await postPromise(import.meta.env.VITE_API_BASE_URL,composeFormData({agencyid: agencyID},ROUTE_IDS.GET_AGENCY));
        const branchInfo = await postPromise(import.meta.env.VITE_API_BASE_URL,composeFormData({agencyid: agencyID},ROUTE_IDS.GET_AGENCY_BRANCH_INFO));
        return Promise.resolve({
            ...agencyData,
            branchID: branchInfo.id,
            branchName: branchInfo.Name
        })
    } catch (error) {
        return Promise.reject(error)
    }
}

export default function useAgency(agencyID, branchID, initialConfig) {
    const {select, enabled=true} = initialConfig || {}
    const config = {
        ...initialConfig,
        enabled: enabled && !!agencyID && !!branchID,
        select: useCallback((data) => {
            const agency = data.find(a => a.id === agencyID)
            return select   
                ? select(agency)
                : agency    
        },[select, branchID, agencyID])
    }
    const query = useQuery(getAgenciesInBranchQueryOptions(branchID,config));
    return query;
}
export const getAgencyQueryOptions = (agencyID) => queryOptions({
    queryKey: [ROUTE_IDS.GET_AGENCY, {agencyID}],
    queryFn: getAgency,
    staleTime: MILLISEC.MIN_14,
    gcTime: MILLISEC.MIN_15
    
})

export const useTestAgency = ({agencyID}) => {
    const query = useQuery(getAgencyQueryOptions(agencyID));
    return query;
}

export const useAgencies = (agencyids: string[]) => {
    const queries = useQueries({
        queries: agencyids.map((id) => getAgencyQueryOptions(id)),
        combine
    })
    return queries
}


const tempData = []
function combine(queries: UseQueryResult[]) {
    const ret = {
        data: [],
        isPending: queries.some(q => q.isPending),
        isError: queries.some(q => q.isError),
        isSuccess: queries.every(q => q.isSuccess)
    }
    if (ret.isPending || ret.isError) 
        return ret
    ret.data = queries.map(q => q.data)
    return ret

}