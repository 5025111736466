import { useAgencies } from "@/api/agencies/getAgency";
import useAllBranches from "@/api/branches/getAllBranches";
import useMyUserData from "@/api/user/getMyUserData";
import { PageHeader } from "@/components/misc/PageHeader";
import { AgencyIcon, ClientIcon, SearchIcon } from "@/components/misc/icons";
import { SimpleCard } from "@/components/ui/SimpleCard";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { useMyAuthorizedAgencies } from "@/hooks/state/useMyAuthorizedAgencies";
import { cn } from "@/lib/utils";
import { AddClientProfilePopover } from "@/routes/Main/Dashboard/components/AddClientProfilePopover";
import { FillInAgencyProfilePopover } from "@/routes/Main/Dashboard/components/FillInAgencyProfilePopover";
import { FindAgencyPopover } from "@/routes/Main/Dashboard/components/FindAgencyPopover";
import { FindCVMNumberPopover } from "@/routes/Main/Dashboard/components/FindCVMNumberPopover";
import { FindClientPopover } from "@/routes/Main/Dashboard/components/FindClientPopover";
import { objToQueryParamStr } from "@/util/routingUtils";
import { motion } from "framer-motion";
import { LucideMessageCircleQuestion, PhoneIcon } from "lucide-react";
import { forwardRef } from "react";
import { Link, useNavigate } from "react-router-dom";
export const Dashboard = ({ ...props }) => {
    const myUserDataQuery = useMyUserData();
    const allBranchesQuery = useAllBranches();
    const nav = useNavigate();
    const { isAdmin } = myUserDataQuery.data || {};
    const d = useAgencies(["4005", "4006"]);
    // console.log("ddata", d.data);
    const authorizedAgenciesQuery = useMyAuthorizedAgencies({
        myUserDataQuery,
        allBranchesQuery,
    });

    function findClient(fields) {
        const { name, number, agency } = fields || {};
        const params = objToQueryParamStr({
            Name: name,
            alias: number,
            AgencyName: agency,
        });
        isAdmin ? nav(`admin/client${params}`) : nav(`client${params}`);
    }
    function findNumber(fields) {
        const { clientName, number } = fields || {};
        const params = objToQueryParamStr({ Name: clientName, alias: number });
        isAdmin
            ? nav(`admin/phonenumber${params}`)
            : nav(`phonenumber${params}`);
        // console.log("find number", fields);
    }
    function findAgency(fields) {
        const { name, city } = fields || {};
        const params = objToQueryParamStr({ Name: name, City: city });
        isAdmin ? nav(`admin/agency${params}`) : nav(`agency/${params}`);
        // console.log("find agency", fields, params);
    }

    function addClientProfile(fields) {
        const { agency, number } = fields || {};
        // console.log("add client profile", fields);
        // console.log(
        //     "path",
        //     `client/${agency.branchID}/${agency.id}/${number.Name}`
        // );
        nav(`client/${agency.branchID}/${agency.id}/${number.Name}`);
    }
    function addAgencyProfile(fields) {
        console.log("add agency profile", fields);
        const { branchID, id: agencyID } = fields || {};
        nav(`agency/${branchID}/${agencyID}`);
    }
    return (
        <div>
            <PageHeader title={"Dashboard"} />     
            <div className="flex flex-col gap-8 justify-between">
                <div className="flex gap-8">
                    <SectionCard
                        className="flex-1 border"
                        title="Quick Actions"
                        Icon={LightningIcon}
                    >
                        <div className="flex flex-col gap-2">
                            <AddClientProfilePopover
                                agenciesQuery={authorizedAgenciesQuery}
                                onSubmit={addClientProfile}
                            >
                                <LinkCard
                                    Icon={ClientIcon}
                                    title="Fill in a client profile"
                                    activeColor={orange}
                                    description="Add profile data to a CVM number"
                                />
                            </AddClientProfilePopover>
                            <FillInAgencyProfilePopover
                                onSubmit={addAgencyProfile}
                                agenciesQuery={authorizedAgenciesQuery}
                            >
                                <LinkCard
                                    Icon={AgencyIcon}
                                    title="Fill in an agency profile"
                                    activeColor={orange}
                                    description="Add profile data to a CVM agency"
                                />
                            </FillInAgencyProfilePopover>
                            <Link to="/reporting">
                                <LinkCard
                                    Icon={ReportIcon}
                                    title="Run a report"
                                    activeColor={orange}
                                    description="Generate a report on usage, demographics, or goal/outcome"
                                />
                            </Link>
                        </div>
                    </SectionCard>

                    <SectionCard
                        className="flex-1"
                        title="Looking for something?"
                        Icon={SearchIcon}
                    >
                        <div className="flex flex-col gap-2">
                            <FindCVMNumberPopover onSubmit={findNumber}>
                                <LinkCard
                                    Icon={PhoneIcon}
                                    title="Search for a CVM number"
                                    activeColor={orange}
                                    description="Find a CVM number by client name or number"
                                />
                            </FindCVMNumberPopover>
                            <FindClientPopover onSubmit={findClient}>
                                <LinkCard
                                    Icon={ClientIcon}
                                    title="Search for a client"
                                    activeColor={orange}
                                    description="Find a current or historical client by name or CVM number"
                                />
                            </FindClientPopover>
                            <FindAgencyPopover onSubmit={findAgency}>
                                <LinkCard
                                    Icon={AgencyIcon}
                                    title="Search for an agency"
                                    activeColor={orange}
                                    description="Find an agency by name or city"
                                />
                            </FindAgencyPopover>
                        </div>
                    </SectionCard>
                </div>
            </div>
            <SimpleCard className="flex-col w-fit gap-1 fixed right-5 bottom-5">
                <div className=" flex items-center gap-4">
                    <LucideMessageCircleQuestion className="w-6 h-6" />
                    <span className="font-medium">Need Help?</span>
                </div>
                <div className="text-sm -mt-3 ml-10 leading-none">
                    Send an email to
                    <a href="mailto:support@lnhs.ca">
                        <Button variant="link" className="text-info px-1">
                            support@lnhs.ca
                        </Button>
                    </a>
                </div>
            </SimpleCard>
        </div>
    );
};

// const FindClientDropdown = ({...props}) => ()

const LinkCard = forwardRef(
    ({ title, description, Icon, activeColor, ...props }, ref) => (
        <motion.button
            ref={ref}
            initial={false}
            animate={{ borderColor: "hsl(214.3 31.8% 91.4%)" }}
            whileHover={{ borderColor: activeColor }}
            className="w-full flex text-left items-center gap-4 border rounded-lg px-4 py-2 hover:cursor-pointer hover:bg-muted"
            {...props}
        >
            <Icon className="w-7 h-7" />
            <div className="flex flex-col">
                <span className="font-medium">{title}</span>
                <span className="text-muted-foreground text-sm">
                    {description}
                </span>
            </div>
        </motion.button>
    )
);
LinkCard.displayName = "LinkCard";

const SectionCard = ({ title, Icon, className, ...props }) => (
    <SimpleCard className={cn("max-w-[40rem]", className)}>
        <div className="flex items-center gap-4 mb-5">
            <div className="rounded-full p-2 flex items-center justify-center bg-background border">
                <Icon
                    className="w-full h-full text-secondary-foreground"
                    strokeWidth={2}
                />
            </div>
            <h2 className="text-2xl font-medium">{title}</h2>
        </div>
        {props.children}
    </SimpleCard>
);

const LightningIcon = (props) => (
    <svg
        {...props}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g fill="none" fillRule="evenodd">
            <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
            <path
                fill="currentColor"
                d="m9.65 4l-3.111 7h3.447c.69 0 1.176.675.958 1.33l-1.656 4.967L16.586 10h-2.57a1.01 1.01 0 0 1-.903-1.462L15.382 4zM8.084 2.6c.162-.365.523-.6.923-.6h7.977c.75 0 1.239.79.903 1.462L15.618 8h3.358c.9 0 1.35 1.088.714 1.724L7.737 21.677c-.754.754-2.01-.022-1.672-1.033L8.613 13H5.015a1.01 1.01 0 0 1-.923-1.42z"
            />
        </g>
    </svg>
);

const ReportIcon = (props) => (
    <svg
        {...props}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill="currentColor"
            d="m16 11.78l4.24-7.33l1.73 1l-5.23 9.05l-6.51-3.75L5.46 19H22v2H2V3h2v14.54L9.5 8z"
        />
    </svg>
);

const orange = "hsl(8, 98 62)";
