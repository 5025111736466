import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { PopoverTrigger, Popover, PopoverContent } from "@/components/ui/popover";
import { ScrollArea } from "@/components/ui/scroll-area";
import { ClientGoals } from "@/util/constants";
import { getSharedArrayItems } from "@/util/miscUtils";
import { createColumnHelper, filterFns } from "@tanstack/react-table";
import { EditIcon, EllipsisIcon, UserMinus } from "lucide-react";
import { useMemo, useState } from "react";

const ch = createColumnHelper();

const stringFilter = filterFns.includesString;

export const branchAdminsColumnHelper = [
    ch.accessor((row) => row.id, {
        id: "ID",
        size: 100,
        meta: {
            hiddenCol: true,
            hiddenFilter: true,
            hideExport: true
        },
        filterFn: stringFilter,
        cell: (info) => <span>{info.getValue()}</span>,
        header: (info) => <span>ID</span>,
    }),
    ch.accessor((row) => `${row?.firstName} ${row?.lastName}`, {
        id: "Name",
        size: 100,
        filterFn: stringFilter,
        cell: (info) => <span>{info.getValue()}</span>,
        header: (info) => <span>Name</span>,
    }),
    ch.accessor((row) => row?.email, {
        id: "Email",
        size: 100,
        filterFn: stringFilter,
        cell: (info) => <span>{info.getValue()}</span>,
        header: (info) => <span>Email</span>,
    }),
    ch.accessor((row) => row.adminLevel, {
        id: "Admin Level",
        size: 100,
        filterFn: stringFilter,
        cell: (info) => <AdminLevel level={info.getValue()}/>,
        header: (info) => <span>Admin Level</span>,
    }),
    ch.accessor((row) => row?.branches?.map(r => r.Name), {
        id: "Authorized Branches",
        size: 100,
        enableSorting: false,
        filterFn: filterFns.arrIncludesSome,
        cell: ({row, table}) => <AuthorizedNodes {...{row, meta: table.options.meta}}/>,
        header: (info) => <span>Branches</span>,
    }),
    ch.display({
        id: "Actions",
        size: 100,
        meta: {
            hideExport: true
        },
        cell: ({row, table}) => 
        // <MoreCell/>,
        <Actions {...{row, table}}/>,
        header: (info) => <span></span>,
    }),
]

const AuthorizedNodes = ({row, meta}) => {
    const [open, setOpen] = useState(false);
    const {branchesQueryData, agenciesQueryData} = meta;
    const adminLevel = Number(row.original?.adminLevel);
    const nodeids = row.original.nodeids;
    if (row.original.firstName.startsWith('passing')) {
        // console.log(adminLevel, nodeids);
    }
    const authorizedNodes = useMemo(() => {
        const targetQuery = adminLevel > 3 ? branchesQueryData : agenciesQueryData;
        if (!targetQuery || targetQuery?.length === 0) return [];
        // console.log("targetQuery", targetQuery)
        // if (targetQuery?.[0] == undefined) console.log(row.original.nodeids, targetQuery);
        return nodeids
            .map(nid => targetQuery.find?.(node => node.id === nid))
            .filter(node => node != null)
            
    } ,[row.adminLevel, nodeids, branchesQueryData, agenciesQueryData])

    const triggerLabel = useMemo(() => {
        return `${authorizedNodes.length} ${adminLevel > 3 ? 'Branches' : 'Agencies'}`
    } ,[adminLevel, authorizedNodes])

    if (adminLevel > 5) {
        return <span>Full Access</span>
    }
    function handleTriggerHover() {
        setOpen(true)
    }
    function handleTriggerHoverEnd() {
        setOpen(false)
    }
    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <span onPointerEnter={handleTriggerHover} onPointerOut={handleTriggerHoverEnd}>
                    {triggerLabel}
                </span>
            </PopoverTrigger>
            <PopoverContent side="right" className="w-fit">
                <ScrollArea className="max-h-[80vh] ">
                    {/* {console.log('authorizedNodes',authorizedNodes, row.original)} */}
                    {authorizedNodes.map((node) => 
                        <p key={node.id} className="mr-2">{node.Name}</p>
                    )}
                </ScrollArea>
            </PopoverContent>
        </Popover>
    )
}



const Actions = ({table,row, })  => {
    const { deleteUser, modUser, agencyIDsInMyDomain, isAdmin } = table.options.meta || {}
    const canModifyRow = useMemo(() => {
        if (isAdmin) return true;
        return getSharedArrayItems(row.original.nodeids, agencyIDsInMyDomain).length > 0
    },[agencyIDsInMyDomain, isAdmin])

    // console.log('canmodifyRow', canModifyRow, row.original.nodeids, agencyIDsInMyDomain, row.original.Name);

    return (
        <div className="float-right">
        { canModifyRow && (
            <DropdownMenu >
                <DropdownMenuTrigger asChild>
                    <Button variant="outline" size={'iconsm'}><EllipsisIcon className="w-5"/></Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent  className="w-56">
                    <DropdownMenuLabel>Actions</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuGroup>
                        <DropdownMenuItem onClick={() => modUser(row?.original)}>
                            <EditIcon className="mr-2 h-5 w-5"/>
                            <span>Edit User</span>
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => deleteUser(row?.original)}>
                            <UserMinus className="mr-2 h-5 w-5"/>
                            <span>Delete User</span>
                        </DropdownMenuItem>
                    </DropdownMenuGroup>
                </DropdownMenuContent>
            </DropdownMenu>
        )}
        </div>
    )
}


const AdminLevel = ({level}) => {
    const levelNum = Number(level);
    // console.log(levelNum);
    if (levelNum > 5) {
        return <Badge>Luma Admin</Badge>
    }
    if (levelNum > 3) {
        return <Badge variant="outline" className="bg-muted-foreground text-muted">Branch Admin</Badge>
    }
    return <Badge variant="outline">Agency Admin</Badge>

}
