import { cn } from '@/lib/utils';
import { motion } from 'framer-motion';
import { Link, useMatch } from 'react-router-dom';

export const ReportTypeCard = ({Icon, title, description, onClick, className, hoverStyle, activeStyle, path,...props}) => {
    const isActive = useMatch({path, end: true}) != null
    return (
        <Link draggable={false} to={path}>
            <motion.div
                transition={{duration: 0.1}}
                whileHover={hoverStyle}
                initial={false}
                animate={isActive ? activeStyle : {
                    border:'2px solid var(--border-for-framer)',
                }}
                className={cn('w-[22rem] rounded-2xl mb-4 border-2 flex items-center px-4 py-3 space-x-6 bg-background h-28 hover:cursor-pointer',
                className)} onClick={onClick}>
                <Icon className='flex-1 h-full'/>
                <div className=" flex-5 flex flex-col gap-1 h-full py-2">
                    <h3 className='text-lg font-bold'>{title}</h3>
                    <p className=' text-sm text-muted-foreground'>{description}</p>
                </div>
            </motion.div>
        </Link>
    );
};