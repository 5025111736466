import { parsePhoneNumber } from "react-phone-number-input"
import { number } from "yup"

export function capitalizeFirst(str) {
    return str?.charAt?.(0)?.toUpperCase?.() + str?.slice?.(1) || ''
}


export function formatToPriceStr(number) {
  const endsWithDec = number?.endsWith?.('.')
  const asNum = Number(number.replace(/[^0-9.]/g, ""))
  const formatted = 
    asNum?.toLocaleString?.(undefined, 
        {minimumFractionDigits: 0, maximumFractionDigits: 2}) 
  const ret = formatted + (endsWithDec ? '.' : '')
  
  return ret
}

export function camelCaseToWords(str, upper=true) {
  const words = str.replace(/([A-Z])/g, ' $1')
    return upper 
      ? words.replace(/^./, (s) => s.toUpperCase()) 
      : words.toLowerCase()
}

export const getDateString = (timestamp) => {
  // console.log(new Date().getTime(), timestamp, new Date().getTime() - timestamp / 1000);
  return new Date(Number(timestamp)).toLocaleDateString('en-CA')
  
  // var date = Date.now(timestamp);
  // var local = date.local().format('ll');
  // return local
  // return timestamp?.length ? local : '';
};

export const getNiceDate = (timestamp) => {
  return new Date(Number(timestamp)).toDateString()
}

export const formatPhoneNumber = (str) => {
  if (!str || str?.length < 10) return str
  var cleaned = ('' + str).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

export function condStr(str) {
  if (str?.includes('null') || str?.includes('undefined')) return ''
  return str
}

export function commaNumber(num) {
  return num?.toLocaleString?.('en-US')
}


export function formatPhone(phoneStr) {
  try {
    return parsePhoneNumber(phoneStr, 'CA').formatNational()
  } catch (error) {
    return phoneStr
  }
}

export function replaceNonAlphaNum(input) {
  if (input == null) return
  return input.replace(/([^a-zA-Z0-9' '])/g, ''); 
}