import { getAgenciesInBranch, getAgenciesInBranchQueryOptions } from "@/api/agencies/getAgenciesInBranch"
import { getAgency, getAgencyQueryOptions } from "@/api/agencies/getAgency"
import useAllBranches from "@/api/branches/getAllBranches"
import useMyUserData from "@/api/user/getMyUserData"
import { useQueries } from "@tanstack/react-query"
import { all } from "axios"
import { m } from "framer-motion"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"

export const useMyAuthorizedAgencies = ({allBranchesQuery, myUserDataQuery}) => {
    const fallbackDataRef = useRef([])
    const {isAgencyUser} = myUserDataQuery.data || {}
    const isPending = (!isAgencyUser && allBranchesQuery.isPending) || myUserDataQuery.isPending
    const isError = (!isAgencyUser && allBranchesQuery.isError) || myUserDataQuery.isError
    const queries = useMemo(() => {
        if (isPending || isError) return fallbackDataRef.current
        // If current user is at the agency level, return all agencies in their nodeids
        if (myUserDataQuery.data?.isAgencyUser)
            return myUserDataQuery.data?.nodeids?.map(getAgencyQueryOptions)
        // If current user is at the branch level, return all agencies in their branch nodeids
        return allBranchesQuery.data
            .filter(b => Number(myUserDataQuery.data?.adminLevel > 5) || myUserDataQuery.data?.nodeids?.includes(b.id))
            .map(b => getAgenciesInBranchQueryOptions(b.id)) 
    },[isPending, allBranchesQuery.data, myUserDataQuery.data])
    const agenciesQuery = useQueries({
        queries, 
        combine: useCallback((queries) => {
            const ret = {
                isPending: queries.some(q => q.isPending),
                isError: queries.some(q => q.isError),
                data: []
            }
            if (ret.isPending || ret.isError) return ret
            ret.data = queries.map(q => q.data).flat()
            // This is unecessary because we get the data necessary inside the component queries
            // ret.data =
            //     queries.map(({data: agencyGroup}, idx) => 
            //     {
                    
            //         const targetBranch = allBranchesQuery.data?.find(b => b.id === myUserDataQuery.data?.nodeids?.[idx])
            //         return agencyGroup.map(a => {
            //             if (!targetBranch?.Name) console.log('no branch',targetBranch)
            //             return ({
            //             ...a,
            //             // branchName: targetBranch?.Name || '',
            //             // branchID: targetBranch?.id || ''
            //         })})
            //     }
            //     ).flat()
            return ret
        },[isPending, allBranchesQuery.data, myUserDataQuery.data, isAgencyUser])
    })

    return agenciesQuery
}