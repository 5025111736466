import { getAgenciesInBranch, getAgenciesInBranchQueryOptions } from "@/api/agencies/getAgenciesInBranch";
import { getAllBranchesQueryOptions } from "@/api/branches/getAllBranches";
import { getBranchesQueryOptions } from "@/api/branches/getBranch";
import { getNumbersInAgencyQueryOptions } from "@/api/numbers/getNumbersInAgency";
import { postPromise } from "@/api/promises";
import { ROUTE_IDS } from "@/api/routeIDs";
import { queryClient } from "@/App";
import { composeFormData } from "@/util/apiUtils";
import { MILLISEC } from "@/util/constants";
import { condenseDuplicateNumbers, filterInactiveNumbers } from "@/util/queryUtils";
import { queryOptions, useQuery } from "@tanstack/react-query";

export const getAllNumbers = async ({ queryKey }) => {
    const { _key } = queryKey;
    const start = performance.now()
    // console.log('running this');
    const numbersPromises = postPromise(
        import.meta.env.VITE_API_BASE_URL,
        composeFormData({}, ROUTE_IDS.GET_ALL_NUMBERS)
    );

    const agenciesPromise = 
        queryClient.ensureQueryData(getAllBranchesQueryOptions(queryClient)).then((branchesData) => {
            return Promise.all(
                branchesData.map(({id}) => queryClient.ensureQueryData(getAgenciesInBranchQueryOptions(id))
            ))
        })

    const [numbersData, agenciesData] = await Promise.all([numbersPromises, agenciesPromise])
    
    const agenciesObject = 
        Object.fromEntries(
            agenciesData.flat().map(({id, isActive}) => [id,isActive])
        )
    numbersData.forEach((n,i) => {
        n.agencyActive = agenciesObject[n.agencyid]
        n.oldTotal = n.totalMsgs
        n.totalMsgs =  Number(n.inbox) + Number(n.deleted)
        if (n.clientdata?.ageRange?.[0] === '46 to 44') {
            n.clientdata.ageRange[0] = '26 to 44'
        }
        // n.oldRead = n.readMsgs
        // Math.max is used because depending on C's logic, we might go over the total quantity of messages
        // n.readMsgs = Math.min(Number(n.readMsgs) + Number(n.deleted), n.totalMsgs)
        // if (i === 0)
        //     console.log('number after extra fields added', n);
    })

    // const agencies = (await Promise.all(agencyPromises)).flat()

    // const numberGroupPromises = agencies.map((a) => 
    //     queryClient.ensureQueryData(getNumbersInAgencyQueryOptions(a.id))
    // )

    // const numbersGroups = (await Promise.all(numberGroupPromises))

    // // Note whether the number the agency is attached to is active
    // numbersGroups.forEach((group, idx) => {
    //     group.forEach((num) => {
    //         const agency = agencies[idx]
    //         num.agencyActive = agency.isActive
    //         num.branchid = agency.branchid
    //         num.branchname = agency.branchname
    //         num.agencyid = agency.id
    //         num.agencyname= agency.Name
    //     })
    // })
    
    // const dedupedNumbers = condenseDuplicateNumbers(numbersGroups.flat())
    const dedupedNumbers = condenseDuplicateNumbers(numbersData)
    const inactiveRemoved = filterInactiveNumbers(dedupedNumbers)

    // console.log(dedupedNumbers[0]);
    // console.log('end time', performance.now() - start);
    // console.log('deduped', dedupedNumbers);
    // const ret = condenseDuplicateNumbers(data)
    return inactiveRemoved;
    // return numbersData
};

export default function useAllNumbers(props) {
    const query = useQuery<any[], Error>({
        ...getAllNumbersQueryOptions,
        ...props,
    });
    return query;
}
export const getAllNumbersQueryOptions = queryOptions({
    queryKey: ["numbers", "all"],
    staleTime: MILLISEC.MIN_14,
    gcTime: MILLISEC.MIN_15,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryFn: getAllNumbers,
});
