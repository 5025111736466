import useAgency from "@/api/agencies/getAgency";
import useAllBranches from "@/api/branches/getAllBranches";
import { ROUTE_IDS } from "@/api/routeIDs";
import { MILLISEC } from "@/util/constants";
import { queryOptions, useQueries, useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';


const exampleAgencies= [
    {
        id: 1,
        name: 'Access',
        displayName: 'Access',
        branch: {
            id: 1,
            name: 'BCVAN',
        },
        status: {
            id: 2,
            name: 'Active',
        },
        clients: [
            {
                id: 1,
                name: 'John Doe',
                number: '123-456-7890',
            }
        ]
    },
    {
        id: 2,
        name: 'WorkBC Westside',
        displayName: 'Westside Workers Association',
        branch: {
            id: 1,
            name: 'BCVAN',
        },
        status: {
            id: 2,
            name: 'Active',
        },
        clients: [
            {
                id: 1,
                name: 'John Doe',
                number: '123-456-7890',
            }
        ]
    },
    {
        id: 3,
        name: 'Wheelin Mobility',
        displayName: 'Human Mobility Services',
        branch: {
            id: 1,
            name: 'BCVAN',
        },
        status: {
            id: 2,
            name: 'Active',
        },
        clients: [
            {
                id: 1,
                name: 'John Doe',
                number: '123-456-7890',
            }
        ]
    },
    {
        id: 4,
        name: 'Calgary Dream Center',
        displayName: 'Dream Nation: Calgary',
        branch: {
            id: 1,
            name: 'BCVAN',
        },
        status: {
            id: 2,
            name: 'Active',
        },
        clients: [
            {
                id: 1,
                name: 'John Doe',
                number: '123-456-7890',
            }
        ]
    },
    {
        id: 5,
        name: 'New Hope',
        status: {
            id: 2,
            name: 'Active',
        },
        displayName: '',
        branch: {
            id: 1,
            name: 'BCVAN',
        },
        clients: [
            {
                id: 1,
                name: 'John Doe',
                number: '123-456-7890',
            }
        ]
    },
]
const exampleBranch1 = {
    id: 1,
    name: 'BCVAN',
    hostAgency: {
        id: 10,
        name: 'BCVAN',
    },
    agencies: exampleAgencies,
    status: {
        id: 2,
        name: 'Active',
    }
}

const exampleBranch2 = {
    id: 2,
    name: 'BCABS',
    hostAgency: {
        id: 20,
        name: 'BCABS',
    },
    agencies: exampleAgencies,
    status: {
        id: 2,
        name: 'Active',
    }
}
const exampleBranch3 = {
    id: 3,
    name: 'BCALB',
    hostAgency: {
        id: 30,
        name: 'BCALB',
    },
    agencies: exampleAgencies,
    status: {
        id: 2,
        name: 'Active',
    }
}
const exampleBranch4 = {
    id: 4,
    name: 'ABGEO',
    hostAgency: {
        id: 40,
        name: 'ABGEO',
    },
    agencies: exampleAgencies,
    status: {
        id: 2,
        name: 'Active',
    }
}
const exampleBranch5 = {
    id: 5,
    name: 'BCGEO',
    hostAgency: {
        id: 50,
        name: 'BCGEO',
    },
    agencies: exampleAgencies,
    status: {
        id: 2,
        name: 'Active',
    }
}
    

export const getBranch = ({queryKey,...props}) => {
    const [_key,{branchID}] = queryKey;

    // console.log('rest of query key', rest); 
    // console.log('getting data for branch with id', branchID);
    let exampleBranch
    switch (branchID) {
        case 1:
            exampleBranch = exampleBranch1
            break;
        case 2:
            exampleBranch = exampleBranch2
            break;
        case 3:
            exampleBranch = exampleBranch3
            break;
        case 4:
            exampleBranch = exampleBranch4
            break;
        case 5:
            exampleBranch = exampleBranch5
            break;
        default:
            exampleBranch = exampleBranch1
            break
    }
    return new Promise(r => r(exampleBranch))
//return errorPromise()
	// postPromise(import.meta.env.VITE_API_BASE_URL,composeFormData(params, ROUTE_IDS.GET_REGIONS));
}

export function useBranches({branchIDs}) {
    const queries = useQueries({
        queries: branchIDs.map(branchID => getBranchesQueryOptions(branchID)),
        combine: useCallback((results) => {
            return {
                data: results.reduce((acc, {data}) => {
                    return [...acc, data]
                }, []),
                isPending: results.some(({isPending}) => isPending),
            }
        },[])
    });
    return queries;
}

export default function useBranch(branchID, hostAgencyID) {
    const agencyQuery = useAgency(hostAgencyID, branchID)
    const branchesQuery = useAllBranches()

    return {
        isPending: agencyQuery.isPending || branchesQuery.isPending,
        isError: agencyQuery.isError || branchesQuery.isError,
        data: {
            ...agencyQuery.data,
            ...branchesQuery.data,
            agencyID: hostAgencyID,
            agencyName: agencyQuery.data?.name,

        }
    };
}
export const getBranchesQueryOptions = (branchID) => queryOptions({
    queryKey: [ROUTE_IDS.GET_REGIONS, {branchID}],
    queryFn: getBranch,
    staleTime: MILLISEC.MIN_14,
    gcTime: MILLISEC.MIN_15
})