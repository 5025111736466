import useAgenciesInBranch from '@/api/agencies/getAgenciesInBranch';
import useAgency, { getAgencyQueryOptions } from '@/api/agencies/getAgency';
import { useAllEnums } from '@/api/enum/getAllEnums';
import useNumbersInAgency from '@/api/numbers/getNumbersInAgency';
import { getMyUserDataQueryOptions } from '@/api/user/getMyUserData';
import { AgencyForm } from '@/components/forms/AgencyForm';
import { Table } from '@/components/tables/Table';
import { clientsColumnHelper } from '@/components/tables/columnHelpers/clientsColumnHelper';
import { phoneNumbersColumnHelper } from '@/components/tables/columnHelpers/phoneNumbersColumnHelper';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { redirect, useParams } from 'react-router-dom';

export const AgencyDetails = ({...props}) => {
    const {branchID, agencyID} = useParams() || {}
    const enumsQuery = useAllEnums()
    const agencyQuery = useAgenciesInBranch(branchID, {select})
    const numbersQuery = useNumbersInAgency(agencyID, {
        select: (data) => {
            console.log('data',data)
            return data.map(num => ({
                ...num,
                agencyid: agencyID,
                branchid: branchID
            }))
        }
    })

    function select(data) {return data.find(a => a.id === agencyID)}
    // const filteredNums = numbersQuery.data?.filter(num => Number(num.timestamp) < 1718785984)
    // console.log(filteredNums?.map(num => num?.timestamp), 1718785984);
    return (
        <div>
            <AgencyForm
                numbersQuery={numbersQuery}
                agencyQuery={agencyQuery}
                enumsQuery={enumsQuery}
            />
            <Card>
                <CardHeader>
                    <CardTitle>Numbers in {agencyQuery.data?.Name}</CardTitle>
                </CardHeader>
                <CardContent>
                    <Table
                        state={{
                            columnVisibility: {
                                Branch: false,
                                Agency: false
                            }
                        }}
                        data={numbersQuery.data ?? []}
                        exportFileName={`agencies_in_branch_${agencyQuery.data?.Name}`}
                        columnHelper={phoneNumbersColumnHelper}
                    >
                    </Table>
                </CardContent>
            </Card>
        </div>
    );
};


export const AgencyDetailsLoader = (queryClient) => async ({params}) => {
    console.log('here in loader');
    const {branchID, agencyID} = params
    // Ensure Permissions
    const myData = await queryClient.ensureQueryData(getMyUserDataQueryOptions)
    const adminLevel = Number(myData?.adminLevel)
    const isAdmin = adminLevel > 5
    const hasBranchPermissions = adminLevel > 3 && myData?.nodeids.includes(branchID)
    const hasAgencyPermissions = adminLevel <= 3 && myData?.nodeids.includes(agencyID)
    if (!isAdmin && !hasBranchPermissions && !hasAgencyPermissions) {
        console.log('insufficient permissions', hasBranchPermissions, hasAgencyPermissions);
        console.log(myData.nodeids, branchID, agencyID);
        return redirect('/')
    }
    // Prefetch data
    const agencyData = await queryClient.ensureQueryData(getAgencyQueryOptions(agencyID))
    if (!agencyData) {
        console.log('did not get agency data', agencyData);
        return redirect('/')
    }
    return {agencyData}
}

export const AgencyDetailsAction = (queryClient) => async ({agencyID}) => {
    
}