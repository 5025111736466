import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion";
import { UsageReportConfig } from "@/routes/Main/Reporting/Presets/UsageReport";
import { UsageReportData } from "@/routes/Main/Reporting/Presets/UsageReport/useUsageReportData";
import { ReportLoader } from "@/routes/Main/Reporting/components/ReportLoader";
import { TextSearchBar } from "@/wrappers/ui/TextSearchBar";
import fuzzySort from "fuzzysort";
import { useEffect, useMemo, useRef, useState } from "react";

type BranchReportProps = {
    data: UsageReportData;
    isPending?: boolean;
    config: UsageReportConfig;
    AgencyReportComponent: (any) => JSX.Element;
    BranchReportComponent: (any) => JSX.Element;
};

export const BranchReport = (props: BranchReportProps) => {
    const {
        config,
        isPending,
        data,
        AgencyReportComponent,
        BranchReportComponent,
        ...rest
    } = props || {};
    const { agencies } = data || {};
    const [search, setSearch] = useState("");
    const [filteredAgencies, setFilteredAgencies] = useState(agencies || []);
    const containerRef = useRef(null);
    const targets = useMemo(prepareAgenciesForFilter, [agencies]);

    useEffect(scrollToContainer, [isPending, containerRef.current]);
    useEffect(filterAgencies, [search, targets]);

    function scrollToContainer() {
        if (containerRef.current && !isPending) {
            containerRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            });
        }
    }
    function prepareAgenciesForFilter() {
        const clone = structuredClone(agencies);
        clone.forEach((a) => {
            (a as any).searchKey = fuzzySort.prepare(
                a.branchname + " " + a.Name
            );
            console.log("after", a);
        });
        console.log("clone", clone);
        return clone;
    }
    function filterAgencies() {
        if (search === "") return setFilteredAgencies(agencies);
        const res = fuzzySort.go(search, targets, { key: "searchKey" });
        setFilteredAgencies(res.map((r) => r.obj));
    }
    console.log("here");
    return config.separateAgencies ? (
        <div ref={containerRef}>
            <ReportLoader isPending={isPending} />
            {/* <ExportButton {...{isExporting, setIsExporting,isPending}}>Export All Agencies</ExportButton> */}
            <TextSearchBar value={search} onChange={setSearch} />
            <Accordion type="single" collapsible>
                {filteredAgencies?.map?.((a) => (
                    <AccordionItem
                        key={a.id}
                        value={String(a.id)}
                        className="bg-background p-2 mt-2 rounded-2xl"
                    >
                        <AccordionTrigger>
                            <span className="ml-2 font-semibold mr-1">
                                {a.branchname}
                            </span>{" "}
                            - {a.name}
                        </AccordionTrigger>
                        <AccordionContent>
                            <AgencyReportComponent {...props} agency={a} />
                            {/* <AgencyReportContent {...props} agency={a} />             */}
                        </AccordionContent>
                    </AccordionItem>
                ))}
            </Accordion>
        </div>
    ) : (
        <div ref={containerRef}>
            <ReportLoader isPending={isPending} />
            <BranchReportComponent
                branch={{ ...config.branch, ...data }}
                {...props}
            />
            {/* <BranchReportContent branch={{...config.branch, ...data}} {...props} /> */}
        </div>
    );
};
