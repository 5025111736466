import { BasicJetDB } from "@/util/constants";
import { omit, pick } from "lodash";

export const getUserLabelFromAdminLevel = (adminLevel) => {
    if (adminLevel > 9) return "Luma Admin";
    if (adminLevel > 4) return "Branch Admin";
    if (adminLevel > 2) return "Agency Admin";
    return 'Unknown'
};

export function throttle(func, limit) {
    let inThrottle = false;

    return function (...args) {
        if (!inThrottle) {
            func.apply(this, args);
            inThrottle = true;

            setTimeout(function () {
                inThrottle = false;
            }, limit);
        }
    };
}

export const isIterable = (obj) => {
    if (obj == null) {
        return false;
    }
    return typeof obj[Symbol.iterator] === "function";
};

export function rangeArray(start, end) {
    if (start > end) return [];
    if (Number.isNaN(start) || Number.isNaN(end)) return [];
    return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
}

export function subObject(obj, keys) {
    // console.log(keys.reduce((a, c) => obj[c] ? (a[c] = obj[c], a) : a, {}));
    return keys.reduce((a, c) => (obj[c] ? ((a[c] = obj[c]), a) : a), {});
}

export function coerceToArray(obj) {
    return Array.isArray(obj) ? obj : [obj];
}

export const curry = (fn) => {
    const curried = (...args) => {
        if (fn.length !== args.length) {
            return curried.bind(null, ...args);
        }
        return fn(...args);
    };
    return curried;
};

export const nullNum = (num) => {
    const cast = Number(num);
    return isNaN(cast) ? null : cast;
};

export const zeroNaN = (num) => {
    const cast = Number(num);
    return isNaN(cast) ? 0 : cast;
};

export function preventDefault(e) {
    e?.preventDefault?.();
}

export function stopPropagation(e) {
    e.stopPropagation();
}

export function updater<T>(newValue) {
    return (prev: T) => {
        return { ...prev, newValue } as T;
    };
}

export function serializeSP(obj) {
    const newObj = Object.entries(obj).map(([k, v]) => [
        k,
        isObject(v) ? JSON.stringify(v) : v,
    ]);
    return new URLSearchParams(newObj as any);
}

export function deserializeSP(searchParams) {
    const obj = Object.fromEntries(searchParams);
    const newObjEntries = Object.entries(obj).map(([k, v]) => [
        k,
        isJsonString(v) ? JSON.parse(v) : v,
    ]);
    const newObj = Object.fromEntries(newObjEntries);
    return newObj;
}

export function searchParamUpdater(newValueObj) {
    if (isFunc(newValueObj))
        return (prevParams) => {
            const prevObj = deserializeSP(prevParams);
            const serialized = serializeSP(newValueObj(prevObj));
            // console.log('prev', prevObj, newValueObj, merge(prevObj, newValueObj));
            const searchParams = new URLSearchParams(serialized);
            return searchParams;
        };
    else
        return () => {
            const serialized = serializeSP(newValueObj);
            const searchParams = new URLSearchParams(serialized);
            return searchParams;
        };
}

// export function searchParamUpdater(newValueObj) {
//   return (prevParams) => {
//     const prevObj = Object.fromEntries(prevParams)
//     console.log('new search params', {...prevObj, ...newValueObj});
//     const searchParams = new URLSearchParams({...prevObj, ...newValueObj})
//     return searchParams
//   }
// }

export function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function isObject(val) {
    return typeof val === "object" && val !== null;
}

export function isFunc(val) {
    if (
        ["[object Function]", "[object AsyncFunction]"].includes(
            Object.prototype.toString.call(val)
        )
    )
        return true;
    return false;
}

export function prettyPrint(obj) {
    console.log(JSON.stringify(obj, null, 2));
}

export function serverEnumToEntries(enumValues) {
    if (!Array.isArray(enumValues)) return [];
    return Object.values(enumValues).map((o: BasicJetDB) => ({
        label: o.Name,
        value: o.id,
    }));
}


export function mergeUnknownAndNoData(obj) {
  const noData = obj?.noData || 0
  const unknown = obj?.Unknown || 0
  return {
    ...omit(obj, ['noData', 'Unknown']) ,
    Unknown: unknown + noData,
  }
}


export function reverseObjectEntries(obj) {
    return Object.fromEntries(Object.entries(obj).map(([k, v]) => [v, k]));
}

export async function fakeAwait(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getSharedArrayItems(arr1, arr2, accessFn=(a)=>a) {
    return arr1.filter((a) => arr2.map(accessFn).includes(accessFn(a)));
}

export function printObjectFields(object, indent=0) {
    const indentStr = rangeArray(0, indent).map(() => '   ').join('')
    for (const [key, value] of Object.entries(object)) {
        if (isObject(value)) {
            if (Array.isArray(value)) {
                if (value.length > 0) {
                    console.log(`${indentStr}${key}: [`);
                    if (isObject(value[0])) {
                        for (const item of value) {
                            printObjectFields(item, indent + 1)
                        }
                    } else {
                        for (const item of value) {
                            console.log(`${indentStr}   ${item}`);
                        }
                    }
                    console.log(`${indentStr} ]`);
                } else {
                    console.log(`${indentStr}${key}: []`);
                }
            } else {
                if (Object.entries(value).length > 0) {
                    console.log(`${indentStr}${key}: {`);
                    printObjectFields(value, indent + 1)
                    console.log(`${indentStr} }`);
                } else {
                    console.log(`${indentStr}${key}: {}`);
                }
            }
            continue
        }
        console.log(`${indentStr}${key}: ${value}`);
    }
}