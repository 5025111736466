import useFlagAgency from "@/api/agencies/flagAgency";
import useAllAgencies from "@/api/agencies/getAllAgencies";
import useAllBranches from "@/api/branches/getAllBranches";
import { getMyUserDataQueryOptions } from "@/api/user/getMyUserData";
import { PageHeader } from "@/components/misc/PageHeader";
import { Table } from "@/components/tables/Table";
import { agenciesColumnHelper } from "@/components/tables/columnHelpers/agenciesColumnHelper";
import { AgencyActiveFilterControls } from "@/components/tables/helpers/AgencyActiveFilterControls";
import { AgencyFlagFilterControls } from "@/components/tables/helpers/AgencyFlagFilterControls";
import { timeStampToFilter } from "@/components/tables/helpers/tableUtils";
import { useQueryResultToasts } from "@/wrappers/toasts";
import { useState } from "react";

export const AllAgencies = ({ ...props }) => {
    const [maxFlaggedTimestamp, setMaxFlaggedTimestamp] = useState(null);
    const [hideActive, setHideActive] = useState(false)
    const allBranchesQuery = useAllBranches();
    const branchEntries = allBranchesQuery.data?.map((b) => [b.id, b.Name]);
    const agenciesQuery = useAllAgencies();
    const queryResultToasts = useQueryResultToasts();
    const flagAgencyMutation = useFlagAgency({
        ...queryResultToasts("Agency Flagged", "Error Flagging Agency"),
    });
    function flagAgency(row: any) {
        flagAgencyMutation.mutate({ agencyid: row.id });
    }
    return (
        <div className="h-full flex flex-col">
            <PageHeader title="All Agencies"></PageHeader>
            <Table
                retrieveSearchParamFilters
                exportFileName="allagencies"
                columnHelper={agenciesColumnHelper}
                data={agenciesQuery.data}
                meta={{ flagAgency }}
                extraFilters={
                    [
                        ...timeStampToFilter(maxFlaggedTimestamp, "Flagged"),
                        {
                            id: "Status",
                            value: hideActive ? 'true' : undefined
                        }
                    ]
                }
                maxHeightPixels={"100%"}
            >
                <AgencyFlagFilterControls
                    {...{ maxFlaggedTimestamp, setMaxFlaggedTimestamp }}
                />
                <AgencyActiveFilterControls 
                    {...{ hideActive, setHideActive }}
                />
            </Table>
        </div>
    );
};

export const AllAgenciesLoader = (queryClient) => async () => {
    try {
        const myData = await queryClient.ensureQueryData({
            queryKey: getMyUserDataQueryOptions.queryKey,
        });
        return {};
    } catch (error) {
        console.log(error);
    }
};
