import { LegendEntry } from "@/components/charting/LegendEntry";
import { BarChartSkeleton } from "@/components/skeletons/BarChartSkeleton";
import { useTheme } from "@/components/theme/theme-provider";
import { GAOReportData } from "@/routes/Main/Reporting/Presets/GoalsAndOutcomesReport/useGAOReportData";
import {
    getChartColors,
    getManualStackedBarConfig,
} from "@/routes/Main/Reporting/utils/ChartConfigs";
import { stackedDemoReportBarOptions } from "@/routes/Main/Reporting/utils/stackedBarOptions";
import { noRed, unknownGray, yesGreen } from "@/util/constants";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { toInteger } from "lodash";
import mergeRefs from "merge-refs";
import { forwardRef, useMemo, useRef } from "react";
import { Bar } from "react-chartjs-2";

type GAOChartProps = {
    data: GAOReportData;
    hideUnknown: boolean;
};
const outcomeColors = [unknownGray, noRed, yesGreen];
export const GAOChart = forwardRef<Chart<"bar", any, unknown>, GAOChartProps>(
    ({ data, hideUnknown=false, ...props }: GAOChartProps, ref) => {
        const {theme} = useTheme()
        const internalRef = useRef();
        const [yLabels, xLabels, datasets, borderColors, oColors] =
            useMemo(() => {
                const { clientGoals } = data;
                if (!clientGoals) return [[], [],[],[],[]];
                const datasetsObj = {
                    Unknown: [],
                    No: [],
                    Yes: [],
                };
                for (const [k, v] of Object.entries(datasetsObj)) {
                    for (const [goal, outcomeObj] of Object.entries(
                        clientGoals
                    )) {
                        v.push(outcomeObj[k] || 0);
                    }
                }
                let dSets = Object.values(datasetsObj);
                let outcomeLabels = [ 'Unknown', 'Goal not met','Goal met',];
                let goalsLabels = Object.keys(clientGoals);
                let oColors = outcomeColors;

                if (hideUnknown) {
                    dSets = dSets.slice(1);
                    outcomeLabels = outcomeLabels.slice(1);
                    oColors = oColors.slice(1);
                }
                const bc = getChartColors(goalsLabels.length, hideUnknown);

                return [outcomeLabels, goalsLabels, dSets, bc, oColors];
            }, [data, hideUnknown]);

        const config = useMemo(() => {
            if (!data.clientGoals) return null;
            return getManualStackedBarConfig(
                datasets,
                borderColors,
                oColors,
                xLabels,
                yLabels
            );
        }, [data, datasets, hideUnknown]);

        return config ? (
            <div className="w-full h-full">
                <span className="font-semibold text-xl">Goals vs Outcomes</span>
                <div className="flex flex-row-reverse justify-center gap-10">
                    {yLabels.map((label, idx) => (
                        <div key={label} className="flex items-center gap-2">
                            <div className={`w-4 h-4 rounded-md`} style={{backgroundColor: oColors[idx]}}></div>
                            <span>{label}</span>
                        </div>
                    ))}
                </div>
                <div className="h-[30rem] w-[99%] relative -mb-2 ">
                    <Bar
                        ref={mergeRefs(ref, internalRef)}
                        options={
                            stackedDemoReportBarOptions({
                                animations: !props?.isExporting,
                                stackedX: true,
                                stackedY: true,
                                legendColor: theme === 'dark' ? 'white' : 'black',
                                borderColors,
                                // showAttachedLabel: false
                            }) as any
                        }
                        plugins={[ChartDataLabels]}
                        {...config}
                    />
                </div>
                {/* <div className="flex flex-col flex-wrap max-h-[5rem] gap-2 justify-center pt-2">
                    {xLabels.map((label, index) => (
                        <LegendEntry
                            key={label}
                            color={borderColors[index]}
                            label={label}
                        />
                    ))}
                </div> */}
            </div>
        ) : (
            <BarChartSkeleton />
        );
    }
);
