import { postPromise } from '@/api/promises';
import { ROUTE_IDS } from '@/api/routeIDs';
import { convertObjFieldsToString } from '@/util/apiUtils';
import { MILLISEC } from '@/util/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const modClient = (params) => {
    params.type = ROUTE_IDS.MOD_CLIENT
    return postPromise(import.meta.env.VITE_API_BASE_URL, convertObjFieldsToString(params), {json: true})
}
export default function useModClient({agencyID, ...props}) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.MOD_CLIENT],
        mutationFn: modClient,
        onSuccess: (data, req) => {
            if (data?.status?.includes?.('not')) {
                // console.log('failed to modify');
                throw new Error(data.status)
            }
            // console.log('req',req, req.objectid);
            try {
                client.invalidateQueries({queryKey: ['numbers']})
                client.setQueryData(['numbers', 'all'], prev => {
                    if (!prev) return
                    // console.log('previous all num',prev, typeof prev, prev?.length);
                    if (!Array.isArray) {
                        // console.log('prev all num data not an array')
                        return prev
                    }
                    else {
                        const targetNumber = prev.find(n => n.Name === req.objectid)
                        const without = prev.filter(n => n.Name !== req.objectid)
                        // console.log('target number to mod', targetNumber);
                        // console.log('after',{...targetNumber, clientdata: {...targetNumber?.clientdata, ...req}});
                        return [
                            ...without,
                            {...targetNumber, clientdata: {...targetNumber?.clientdata, ...req}}
                        ]
                    }
                })
                client.setQueryData([ROUTE_IDS.GET_NUMBERS_IN_AGENCY, {"agencyid": `${agencyID}`}], prev => {
                    // console.log('prev num in agency', prev, data.objectid);
                    if (!prev) return

                    const targetNumber = prev.find(n => n.Name === req.objectid)
                    const filtered = prev.filter(n => n.Name !== req.objectid)
                    return [...filtered,{...targetNumber, clientdata: {...targetNumber?.clientData, ...req}}]
                })
                
            } catch (error) {
                console.log('error in success fn', error);          
            }
            onSuccess && onSuccess(data, req)
                
        },
        ...otherProps
    });
    return mutation;
}