import { getAgencyQueryOptions } from '@/api/agencies/getAgency';
import { postPromise } from '@/api/promises';
import { ROUTE_IDS } from '@/api/routeIDs';
import { composeFormData } from '@/util/apiUtils';
import { MILLISEC } from '@/util/constants';
import { QueryClient, QueryOptions, queryOptions, useQuery, useQueryClient } from '@tanstack/react-query';

export const getAllBranches =  ({queryKey}, queryClient: QueryClient) =>  {
    const {_key} = queryKey;
    return new Promise((resolve) => {
	postPromise(import.meta.env.VITE_API_BASE_URL,composeFormData({}, ROUTE_IDS.GET_ALL_REGIONS))
    .then(async data => {
        try {
            if (Array.isArray(data)) {
                const promises = []
                const branchesWithoutHost = []
                for (const b of data) {
                    if (b.hostAgency) {
                        promises.push(
                                queryClient.ensureQueryData(getAgencyQueryOptions(b.hostAgency))
                                .then((host) => {
                                    b.hostAgencyData = host
                                    return b
                                })
                        )
                    } else {
                        branchesWithoutHost.push(b)
                    }
                }
                    const final = await Promise.all(promises)
                    // console.log('final promises', [...final, ...branchesWithoutHost]);
                    resolve([...final, ...branchesWithoutHost])
            }
        } catch (error) {
            console.log(error);
        }
    })
})
	return postPromise(import.meta.env.VITE_API_BASE_URL,composeFormData({}, ROUTE_IDS.GET_ALL_REGIONS));
}
export default function useAllBranches(props?: Partial<QueryOptions>) {
    const client = useQueryClient();
    const query = useQuery({...getAllBranchesQueryOptions(client),...props});
    return query;
}
export const getAllBranchesQueryOptions = (queryClient) => queryOptions({
    placeholderData: [],
    queryKey: [ROUTE_IDS.GET_ALL_REGIONS],
    queryFn: (params) => getAllBranches(params, queryClient),
    staleTime: MILLISEC.MIN_14,
    gcTime: MILLISEC.MIN_15
})