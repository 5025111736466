import { downloadCSV } from "@/util/fileUtils";
import { compareItems, rankItem } from "@tanstack/match-sorter-utils";
import { createRow, memo, sortingFns } from "@tanstack/react-table";
import { go, prepare, single } from "fuzzysort";

export function exportTableAsCSV(table, exportFileName) {
    const flatRows = table.getRowModel().flatRows;
    const headers = table
        .getAllColumns()
        .filter(
            (col) =>
                col.getIsVisible() &&
                !col.columnDef?.meta?.hideExport === true &&
                !col.columnDef?.meta?.hiddenCol === true
        )
        .map((col) => col.columnDef.id);
    const rows = flatRows.map((row) =>
        headers.map((header) => {
            let exportFn =
                table.getColumn(header).columnDef.meta?.exportFn ||
                function (row) {
                    return row;
                };
            const rowValue = row.getValue(header);
            return rowValue == null ? "–" : exportFn(rowValue);
        })
    );
    downloadCSV(exportFileName, headers, rows);
}

export function getDetachedChildrenFilteredRowModel() {
    return (table) =>
        memo(
            () => [
                table.getPreFilteredRowModel(),
                table.getState().columnFilters,
                table.getState().globalFilter,
            ],
            (rowModel, columnFilters, globalFilter) => {
                if (
                    !rowModel.rows.length ||
                    (!(columnFilters != null && columnFilters.length) &&
                        !globalFilter)
                ) {
                    for (let i = 0; i < rowModel.flatRows.length; i++) {
                        rowModel.flatRows[i].columnFilters = {};
                        rowModel.flatRows[i].columnFiltersMeta = {};
                    }
                    return rowModel;
                }
                const resolvedColumnFilters = [];
                const resolvedGlobalFilters = [];
                (columnFilters != null ? columnFilters : []).forEach((d) => {
                    var _filterFn$resolveFilt;
                    const column = table.getColumn(d.id);
                    if (!column) {
                        return;
                    }
                    const filterFn = column.getFilterFn();
                    if (!filterFn) {
                        console.warn(
                            `Could not find a valid 'column.filterFn' for column with the ID: ${column.id}.`
                        );
                        return;
                    }
                    resolvedColumnFilters.push({
                        id: d.id,
                        filterFn,
                        resolvedValue:
                            (_filterFn$resolveFilt =
                                filterFn.resolveFilterValue == null
                                    ? void 0
                                    : filterFn.resolveFilterValue(d.value)) !=
                            null
                                ? _filterFn$resolveFilt
                                : d.value,
                    });
                });
                const filterableIds = columnFilters.map((d) => d.id);
                const globalFilterFn = table.getGlobalFilterFn();
                const globallyFilterableColumns = table
                    .getAllLeafColumns()
                    .filter((column) => column.getCanGlobalFilter());
                if (
                    globalFilter &&
                    globalFilterFn &&
                    globallyFilterableColumns.length
                ) {
                    filterableIds.push("__global__");
                    globallyFilterableColumns.forEach((column) => {
                        var _globalFilterFn$resol;
                        resolvedGlobalFilters.push({
                            id: column.id,
                            filterFn: globalFilterFn,
                            resolvedValue:
                                (_globalFilterFn$resol =
                                    globalFilterFn.resolveFilterValue == null
                                        ? void 0
                                        : globalFilterFn.resolveFilterValue(
                                              globalFilter
                                          )) != null
                                    ? _globalFilterFn$resol
                                    : globalFilter,
                        });
                    });
                }
                let currentColumnFilter;
                let currentGlobalFilter;
                for (let j = 0; j < rowModel.flatRows.length; j++) {
                    const row = rowModel.flatRows[j];
                    row.columnFilters = {};
                    if (resolvedColumnFilters.length) {
                        for (let i = 0; i < resolvedColumnFilters.length; i++) {
                            currentColumnFilter = resolvedColumnFilters[i];
                            const id = currentColumnFilter.id;
                            row.columnFilters[id] =
                                currentColumnFilter.filterFn(
                                    row,
                                    id,
                                    currentColumnFilter.resolvedValue,
                                    (filterMeta) => {
                                        row.columnFiltersMeta[id] = filterMeta;
                                    }
                                );
                        }
                    }
                    if (resolvedGlobalFilters.length) {
                        for (let i = 0; i < resolvedGlobalFilters.length; i++) {
                            currentGlobalFilter = resolvedGlobalFilters[i];
                            const id = currentGlobalFilter.id;
                            if (
                                currentGlobalFilter.filterFn(
                                    row,
                                    id,
                                    currentGlobalFilter.resolvedValue,
                                    (filterMeta) => {
                                        row.columnFiltersMeta[id] = filterMeta;
                                    }
                                )
                            ) {
                                row.columnFilters.__global__ = true;
                                break;
                            }
                        }
                        if (row.columnFilters.__global__ !== true) {
                            row.columnFilters.__global__ = false;
                        }
                    }
                }
                const filterRowsImpl = (row) => {
                    for (let i = 0; i < filterableIds.length; i++) {
                        if (row.columnFilters[filterableIds[i]] === false) {
                            return false;
                        }
                    }
                    return true;
                };
                return filterRows(rowModel.rows, filterRowsImpl, table);
            },
            {
                key: "getFilteredRowModel",
                debug: () => {
                    var _table$options$debugA;
                    return (_table$options$debugA = table.options.debugAll) !=
                        null
                        ? _table$options$debugA
                        : table.options.debugTable;
                },
                onChange: () => {
                    table._autoResetPageIndex();
                },
            }
        );

    function filterRows(rows, filterRowImpl, table) {
        if (table.options.filterFromLeafRows) {
            return filterRowModelFromLeafs(rows, filterRowImpl, table);
        }
        return filterRowModelFromRoot(rows, filterRowImpl, table);
    }
    function filterRowModelFromLeafs(rowsToFilter, filterRow, table) {
        var _table$options$maxLea;
        const newFilteredFlatRows = [];
        const newFilteredRowsById = {};
        const maxDepth =
            (_table$options$maxLea = table.options.maxLeafRowFilterDepth) !=
            null
                ? _table$options$maxLea
                : 100;
        const recurseFilterRows = function (rowsToFilter2, depth) {
            if (depth === void 0) {
                depth = 0;
            }
            const rows = [];
            for (let i = 0; i < rowsToFilter2.length; i++) {
                var _row$subRows;
                let row = rowsToFilter2[i];
                const newRow = createRow(
                    table,
                    row.id,
                    row.original,
                    row.index,
                    row.depth,
                    void 0,
                    row.parentId
                );
                newRow.columnFilters = row.columnFilters;
                if (
                    (_row$subRows = row.subRows) != null &&
                    _row$subRows.length &&
                    depth < maxDepth
                ) {
                    newRow.subRows = recurseFilterRows(row.subRows, depth + 1);
                    row = newRow;
                    if (filterRow(row) && !newRow.subRows.length) {
                        rows.push(row);
                        newFilteredRowsById[row.id] = row;
                        newFilteredFlatRows.push(row);
                        continue;
                    }
                    if (filterRow(row) || newRow.subRows.length) {
                        rows.push(row);
                        newFilteredRowsById[row.id] = row;
                        newFilteredFlatRows.push(row);
                        continue;
                    }
                    // Add child rows without parent if the parent does not match
                    if (!filterRow(row) && newRow.subRows.length) {
                        newRow.subRows.forEach((subRow) => {
                            rows.push(subRow);
                            newFilteredRowsById[subRow.id] = subRow;
                            newFilteredFlatRows.push(subRow);
                        });
                    }
                } else {
                    row = newRow;
                    if (filterRow(row)) {
                        rows.push(row);
                        newFilteredRowsById[row.id] = row;
                        newFilteredFlatRows.push(row);
                    }
                }
            }
            return rows;
        };
        return {
            rows: recurseFilterRows(rowsToFilter),
            flatRows: newFilteredFlatRows,
            rowsById: newFilteredRowsById,
        };
    }
}

export function filterRowModelFromRoot(rowsToFilter, filterRow, table) {
    var _table$options$maxLea2;
    const newFilteredFlatRows = [];
    const newFilteredRowsById = {};
    const maxDepth =
        (_table$options$maxLea2 = table.options.maxLeafRowFilterDepth) != null
            ? _table$options$maxLea2
            : 100;
    const recurseFilterRows = function (rowsToFilter2, depth) {
        if (depth === void 0) {
            depth = 0;
        }
        const rows = [];
        for (let i = 0; i < rowsToFilter2.length; i++) {
            let row = rowsToFilter2[i];
            const pass = filterRow(row);
            if (pass) {
                var _row$subRows2;
                if (
                    (_row$subRows2 = row.subRows) != null &&
                    _row$subRows2.length &&
                    depth < maxDepth
                ) {
                    const newRow = createRow(
                        table,
                        row.id,
                        row.original,
                        row.index,
                        row.depth,
                        void 0,
                        row.parentId
                    );
                    newRow.subRows = recurseFilterRows(row.subRows, depth + 1);
                    row = newRow;
                }
                rows.push(row);
                newFilteredFlatRows.push(row);
                newFilteredRowsById[row.id] = row;
            } else if (row.subrows) {
                // console.log('has subrows', row.subrows);
                return recurseFilterRows(row.subRows, depth + 1);
            }
        }
        return rows;
    };
    return {
        rows: recurseFilterRows(rowsToFilter),
        flatRows: newFilteredFlatRows,
        rowsById: newFilteredRowsById,
    };
}

export const fuzzyFilter = (row, columnId, value, addMeta) => {
    // Rank the item
    if (!row.columnFiltersMeta?.[columnId]?.prepped) {
        const preparedValue = prepare(row.getValue(columnId))
        addMeta({
            prepped: preparedValue
        })
    } else {
    }
    const preparedValue = row.columnFiltersMeta[columnId].prepped
    const filterResult = single(value, preparedValue)
    if (filterResult == null) {
        return false
    } else {
    }
    // Return if the item should be filtered in/out
    return filterResult.score > 0.5;
};

export const fuzzySort = (rowA, rowB, columnId) => {
    let dir = 0;
    // Only sort by rank if the column has ranking information
    if (rowA.columnFiltersMeta[columnId]) {
        dir = compareItems(
            rowA.columnFiltersMeta[columnId]?.itemRank,
            rowB.columnFiltersMeta[columnId]?.itemRank
        );
    }

    // Provide an alphanumeric fallback for when the item ranks are equal
    return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

export function timeStampToFilter(timestamp, field) {
    return [
        {
            id: field,
            value: timestamp,
        },
    ];
}
