import useAllBranches from "@/api/branches/getAllBranches";
import { getAllNumbersQueryOptions } from "@/api/numbers/getAllNumbers";
import useMyUserData, {
    getMyUserDataQueryOptions,
} from "@/api/user/getMyUserData";
import { PageHeader } from "@/components/misc/PageHeader";
import { Table } from "@/components/tables/Table";
import { phoneNumbersColumnHelper } from "@/components/tables/columnHelpers/phoneNumbersColumnHelper";
import { ShowActiveNumbersControls } from "@/components/tables/helpers/ShowActiveNumbersControls";
import { timeStampToFilter } from "@/components/tables/helpers/tableUtils";
import { useAuthorizedPhoneNumbers } from "@/hooks/state/useAuthorizedPhoneNumbers";
import { useMyAuthorizedAgencies } from "@/hooks/state/useMyAuthorizedAgencies";
import { useObjectSearchParams } from "@/hooks/state/useObjectSearchParams";
import { JCheckbox } from "@/wrappers/ui/JCheckbox";
import { useState } from "react";
import { redirect } from "react-router-dom";

export const PhoneNumbers = ({ ...props }) => {
    const [minLastReadTime, setMinLastReadTime] = useState(null);
    const [showNiceDates, setShowNiceDates] = useState(false);
    const myUserDataQuery = useMyUserData();
    const allBranchesQuery = useAllBranches();
    const agenciesQuery = useMyAuthorizedAgencies({
        myUserDataQuery,
        allBranchesQuery,
    });

    const authorizedNumbersQuery = useAuthorizedPhoneNumbers({ agenciesQuery });

    return (
        <div className="h-full flex flex-col">
            <PageHeader title={"Phone Numbers"}></PageHeader>
            <Table
                retrieveSearchParamFilters
                showColumnVisibility
                exportFileName="phone_numbers"
                pdfName={"Phone Numbers"}
                data={authorizedNumbersQuery.data}
                columnHelper={phoneNumbersColumnHelper}
                maxHeightPixels={"100%"}
                extraFilters={[
                    ...timeStampToFilter(minLastReadTime, "Last Read"),
                    {
                        id: "Agency Active",
                        value: "true"
                    }
                ]}
                meta={{
                    showNiceDates
                }}
            >
                <ShowActiveNumbersControls
                    minLastReadTime={minLastReadTime}
                    setMinLastReadTime={setMinLastReadTime}
                />
                <JCheckbox
                    className="py-1 h-10 bg-background"
                    value={showNiceDates}
                    handleChange={setShowNiceDates}
                    label="Show Nice Dates"
                />
            </Table>
        </div>
    );
};

export const allPhoneNumbersLoader = (queryClient) => async () => {
    const myData = await queryClient.ensureQueryData(getMyUserDataQueryOptions);
    if (!Number(myData.adminLevel) > 10) redirect("/");
    const allNumbersData = queryClient.ensureQueryData(
        getAllNumbersQueryOptions
    );
    return { allNumbersData };
};
