import useAgenciesInBranch, { getAgenciesInBranchQueryOptions } from '@/api/agencies/getAgenciesInBranch';
import { getNumbersInAgencyQueryOptions } from '@/api/numbers/getNumbersInAgency';
import { queryClient } from '@/App';
import { condenseDuplicateNumbers } from '@/util/queryUtils';
import { useQueries } from '@tanstack/react-query';
import { useCallback } from 'react';

export const getNumbersInBranch = async ({queryKey}) => {
    const [_key, {branchid}] = queryKey;
    const agencies = await queryClient.ensureQueryData(getAgenciesInBranchQueryOptions(branchid))
    const numbersPromises = agencies.map(({id}) =>
        queryClient.ensureQueryData(getNumbersInAgencyQueryOptions(id))
    )
    
    const numbers = (await Promise.all([numbersPromises])).flat()
    return condenseDuplicateNumbers(numbers)
}

export default function useNumbersInBranch(branchid, branchname, config={}) {
    const agenciesQuery = useAgenciesInBranch(branchid)
    const queries = useQueries({
        queries: agenciesQuery.data 
            ? agenciesQuery.data?.map?.(a => ({...getNumbersInAgencyQueryOptions(a.id), ...config}))
            : [],
        combine: useCallback(queries => combine(queries, agenciesQuery.data, branchname), [agenciesQuery.data, branchname])
    })
    return queries
}

export function useNumbersInBranches(branchids, branchnames, enabled=true) {
    const agenciesQueries = useQueries({
        queries: enabled 
            ? branchids.map((branchid) => getAgenciesInBranchQueryOptions(branchid)) 
            : [],
        combine: useCallback(queries => {
            const isPending = queries.some(q => q.isPending)
            if (isPending) return {data:null, isPending}
            const data = queries.map(q => q.data)?.flat?.()
            return {
                data,
                isPending
            }
        }, [branchnames])
    })
    const numbersQueries = useQueries({
        queries: agenciesQueries.data?.map((a, idx) => getNumbersInAgencyQueryOptions(a.id, branchnames[idx])),
        combine: useCallback(queries => combine(queries, agenciesQueries.data, branchnames), [agenciesQueries.data, branchnames])
    })
    return numbersQueries

}
// export const getNumbersInBranchQueryOptions = (branchid) => queryOptions({
//     queryKey: [ROUTE_IDS.GET_NUMBERS_IN_AGENCY, {branchid}],
//     queryFn: getNumbersInBranch,
// }) 

function combine(queries, agencies, branchname) {
    const isPending = queries.some(q => q.isPending)
    if (isPending) return {data:null, isPending}
    const data = queries.map((q,idx) => {
        for (const number of q.data) {
            number.agencyname = agencies[idx].Name
            number.branchname = branchname
        }
        return q.data
    }).flat()
    return {
        data: data,
        isPending
    }
}