import { QueryClient } from "@tanstack/react-query";
import { getAllBranchesQueryOptions } from "./branches/getAllBranches";
import { getAllEnumsQueryOptions } from "./enum/getAllEnums";
import { getMyUserDataQueryOptions } from "./user/getMyUserData";
import { getBranchAdminsQueryOptions } from "./user/getBranchAdmins";

export function addDefaultQueryOptions(queryClient: QueryClient) {
    const queries = [
        getMyUserDataQueryOptions,
        // getBranchAdminsQueryOptions,
        // getAllBranchesQueryOptions(queryClient),
        getAllEnumsQueryOptions,    
    ]
    for (const query of queries) {
        // console.log(query as DefaultOptions);
        queryClient.setQueryDefaults(query.queryKey, query)
    }
}