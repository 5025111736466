import { useLogin } from '@/api/auth/login';
import { getMyUserDataQueryOptions } from '@/api/user/getMyUserData';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Form } from '@/components/ui/form';
import { useToast } from '@/components/ui/use-toast';
import { useRHF } from '@/hooks/useRHF';
import { useYupValidationResolver } from '@/hooks/useYupValidationResolver';
import { deleteAllCookies, setCookie } from '@/util/cookieUtils';
import { checkFormForAutofill } from '@/util/formUtils';
import { ComposedForm } from '@/wrappers/ui/form/ComposedForm';
import { ComposedFormText } from '@/wrappers/ui/form/ComposedFormText';
import Yup from '@/wrappers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { jwtDecode } from 'jwt-decode';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const validationSchema = Yup.object({
    email: Yup.string().email().reqan('email').default(''),
    password: Yup.string().reqa('password').default('').trim(),
})
export const Login = ({...props}) => {
    const {successToast, errorToast} = useToast()
    const nav = useNavigate()
    const location = useLocation()
    const client = useQueryClient()
    const loginMutation = useLogin({
        onSuccess: handleLoginSuccess,
        onError: handleLoginError
    })

    const resolver = useYupValidationResolver(validationSchema);
    const form
        = useRHF({resolver, defaultValues: validationSchema.getDefault(), mode: 'onBlur'});
    const {register, handleSubmit, formState, setValue, reset, control} = form

    async function handleLoginSuccess(data) {
        deleteAllCookies();
        successToast('Login Successful')
        const decodedToken = jwtDecode(data.token)
        const decodedRefreshToken = jwtDecode(data.refreshtoken) 
        setCookie('token', data.token, decodedToken.exp * 1000)
        setCookie('refreshToken', data.refreshtoken, decodedRefreshToken.exp * 1000)
        const myData = await client.ensureQueryData(getMyUserDataQueryOptions)
        if (myData) {
            location.state?.from
                ? nav(location.state.from)
                : nav('/')
        }
    }

    function handleLoginError(data,...rest) {
        if (data.response.status === 400) {
            errorToast('Email or password was incorrect')
        } else {
            errorToast('Login Failed')
        }

    }
    function login(fields) {
        loginMutation.mutate(fields)
    }

    

    return (
            <Card className='w-[30rem] ' {...props} >
                <CardHeader>
                    <CardTitle>Login to CVM</CardTitle>
                </CardHeader>
                <ComposedForm {...{form, onSubmit: login}}>
                    <CardContent className='flex flex-col'>
                            <ComposedFormText
                                {...{
                                    control,
                                    variant: 'medium',
                                    name: 'email',
                                    autoComplete: 'email',
                                    label: 'Email',
                                    placeholder: 'johnsmith@gmail.com',
                                }}
                            />
                            <ComposedFormText
                                {...{
                                    control,
                                    type: 'password',
                                    variant: 'medium',
                                    name: 'password',
                                    autoComplete: 'current-password',
                                    label: 'Password',
                                    placeholder: 'Something sneaky...',
                                }}
                            />
                    </CardContent>
                    <CardFooter className='block'>
                        <Button  variant={'default'} className='w-full' type='submit'>
                            Login
                        </Button>
                        <Link to={'/auth/forgot-password'}>
                            <p className='hover:cursor-pointer text-muted-foreground text-sm mt-2 font-mediumx  '>
                                Forgot Password?
                            </p>
                        </Link>
                    </CardFooter>
                </ComposedForm>
            </Card>
    );
};
