import { getAllNumbersQueryOptions } from '@/api/numbers/getAllNumbers';
import { getNumbersInAgencyQueryOptions } from '@/api/numbers/getNumbersInAgency';
import { getMyUserDataQueryOptions } from '@/api/user/getMyUserData';
import { PageHeader } from '@/components/misc/PageHeader';
import { cn } from '@/lib/utils';
import { ReportTypeCard } from '@/routes/Main/Reporting/components/ReportTypeCard';
import { QueryClient } from '@tanstack/react-query';
import { Sparkles } from 'lucide-react';
import { Outlet, useLocation } from 'react-router-dom';

export const Reporting = ({...props}) => {
    const loc = useLocation()
    return (
        <div className='h-[90vh]'>
            <PageHeader title={'Reporting'} />
            <div className="flex flex-col space-y-4 rounded-2xl h-full">
                <div className="flex flex-col  bg-background px-6 py-4 rounded-2xl border">
                    <h3 className='font-bold text-lg mb-3'>Select a report</h3>
                    <div className="flex border-0 border-border gap-x-4 flex-wrap">
                            {/* <ReportTypeCard
                                path={'/reporting/customreport'}
                                hoverStyle={{
                                    boxShadow: 'rgb(250 204 21) 0px 0px 5px',
                                    border: '2px solid rgb(250 204 21)'
                                }}
                                activeStyle={{
                                    border: '2px solid rgb(250 204 21)'
                                }}
                                Icon={CustomReportIcon}
                                title={'Create a Custom Report'}
                                description={'Do whatever. Show whatever'}
                            /> */}
                            {/* <ReportTypeCard 
                                path={'/reporting/presets/30dayinactivity'}
                                hoverStyle={{
                                    boxShadow: '#22d3ee 0px 0px 5px',
                                    border: '2px solid #22d3ee'
                                }}
                                activeStyle={{
                                    border: '2px solid #22d3ee'
                                }}
                                Icon={InactiveIcon} 
                                title={'Inactive for 30 days'}
                                description={'Shows which numbers haven\'t read a message in the past 30 days.'}>
                            </ReportTypeCard> */}
                            <ReportTypeCard 
                                path={'/reporting/presets/demographics'}
                                hoverStyle={{
                                    boxShadow: '#c084fc 0px 0px 5px',
                                    border: '2px solid #c084fc'
                                }}
                                activeStyle={{
                                    border: '2px solid #c084fc'
                                }}
                                className={'border-2'}
                                Icon={DemographicsIcon} 
                                title={'Demographics'}
                                description={'Shows the distributions of demographic information (Ex. age).'}>
                            </ReportTypeCard>
                            <ReportTypeCard 
                                path={'/reporting/presets/usage'}
                                hoverStyle={{
                                    boxShadow: '#2dd4bf 0px 0px 5px',
                                    border: '2px solid #2dd4bf'
                                }}
                                activeStyle={{
                                    border: '2px solid #2dd4bf'
                                }}
                                Icon={UsageIcon} 
                                title={'Usage'}
                                description={'Shows CVM client activity.'}>
                            </ReportTypeCard>
                            <ReportTypeCard 
                                path={'/reporting/presets/goalsandoutcomes'}
                                hoverStyle={{
                                    boxShadow: '#ef4444 0px 0px 5px',
                                    border: '2px solid #ef4444'
                                }}
                                activeStyle={{
                                    border: '2px solid #ef4444'
                                }}
                                Icon={GoalAndOutcomeIcon} 
                                title={'Goals and and Outcomes'}
                                description={'Shows correlation between client goals and outcomes'}>
                            </ReportTypeCard>
                    </div>
                </div>
                <Outlet/>
                {/* <div className="flex-1 ">
                </div> */}
            </div>
        </div>
    );
};

const GoalAndOutcomeIcon = ({className, ...props}) => (
    <svg {...props}   className={cn(' py-5',className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path className='fill-red-500' fill="blue" d="M14.844 20H6.5C5.121 20 4 18.879 4 17.5S5.121 15 6.5 15h7c1.93 0 3.5-1.57 3.5-3.5S15.43 8 13.5 8H8.639a9.812 9.812 0 0 1-1.354 2H13.5c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5h-7C4.019 13 2 15.019 2 17.5S4.019 22 6.5 22h9.593a10.415 10.415 0 0 1-1.249-2M5 2C3.346 2 2 3.346 2 5c0 3.188 3 5 3 5s3-1.813 3-5c0-1.654-1.346-3-3-3m0 4.5a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 5 6.5"/>
        <path className='fill-blue-500' fill="red" d="M19 14c-1.654 0-3 1.346-3 3c0 3.188 3 5 3 5s3-1.813 3-5c0-1.654-1.346-3-3-3m0 4.5a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 19 18.5"/>
    </svg>
)

const UsageIcon = ({className, ...props}) => (
    <svg {...props}   className={cn('text-teal-400 py-5',className)} width="24" height="24" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M48 320h64l64-256l64 384l64-224l32 96h64"/><circle cx="432" cy="320" r="32" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32"/></svg>
)

const DemographicsIcon = ({className, ...props}) => (
    <img {...props}   className={cn('text-cyan-500 py-5',className)} src="/demographics.png"/>
)

const CustomReportIcon = ({className, ...props}) => (
    <Sparkles {...props} className={cn('text-yellow-400',className)}/>
)
const InactiveIcon = ({className, ...props}) => (
    <svg {...props} className={cn('text-cyan-500 ',className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="M24 0v24H0V0zM12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036c-.01-.003-.019 0-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z"/><path fill="currentColor" d="M12 4a1 1 0 0 0 1 1h4.586l-5.293 5.293A1 1 0 0 0 13 12h7a1 1 0 1 0 0-2h-4.586l5.293-5.293A1 1 0 0 0 20 3h-7a1 1 0 0 0-1 1m-8 6a1 1 0 1 1 0-2h5a1 1 0 0 1 .707 1.707L6.414 13H9a1 1 0 1 1 0 2H4a1 1 0 0 1-.707-1.707L6.586 10zm8 7a1 1 0 1 1 0-2h4a1 1 0 0 1 .707 1.707L14.414 19H16a1 1 0 1 1 0 2h-4a1 1 0 0 1-.707-1.707L13.586 17z"/></g></svg>
)


export const ReportingLoader = (queryClient: QueryClient) => async () => {
    try {
        const myData = await queryClient.ensureQueryData(getMyUserDataQueryOptions)
        const adminLevel = Number(myData?.adminLevel)
        const isAgencyUser = adminLevel === 3
        if (isAgencyUser) {
            for (const agencyid of myData.nodeids) {
                queryClient.prefetchQuery(getNumbersInAgencyQueryOptions(agencyid))
            }
        } else {
            queryClient.prefetchQuery(getAllNumbersQueryOptions)
        }
    } catch (error) {
        
    }
    return {}
}