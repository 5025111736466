import { Badge } from "@/components/ui/badge";

export const StatusCell = ({ isActive, ...props }) => {
    if (isActive == null)
        return (
            <div>
                <span className="text-muted-foreground text-xs">No status</span>
            </div>
        );
    return isActive || isActive === 'true' ? (
        <Badge
            variant="success"
        >
            Active
        </Badge>
    ) : (
        <Badge variant="secondary">Inactive</Badge>
    );
};
