import { deserializeSP, isFunc, searchParamUpdater } from '@/util/miscUtils';
import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useObjectSearchParams = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const state = useMemo(deserialize,[searchParams])

    function deserialize() {
        return deserializeSP(searchParams)
    }

    function serializeAndMutate(update) {
        // console.log('update',update);
        setSearchParams(searchParamUpdater(update))
    }

    return [state, serializeAndMutate]
};
