import { Checkbox } from '@/components/ui/checkbox';
import { MILLISEC } from '@/util/constants';
import { JCheckbox } from '@/wrappers/ui/JCheckbox';
import { Select } from '@/wrappers/ui/Select';
import { addDays } from 'date-fns';
import { set } from 'lodash';
import React, { useEffect, useMemo } from 'react';

export const AgencyFlagFilterControls = ({maxFlaggedTimestamp, setMaxFlaggedTimestamp, ...props}) => {
    const selectOptions = useMemo(() => {
        const today = new Date()
        return [
            {
                value: addDays(today,-30).getTime(),
                label : '30 days ago'
            },
            {
                value: addDays(today,-45).getTime(),
                label : '45 days ago'
            },
            {
                value: addDays(today,-60).getTime(),
                label : '60 days ago'
            },
        ]
    },[])
    const [enabled, setEnabled] = React.useState(false)
    const [internalMax,setInternalMax] = React.useState(selectOptions[0].value)
    function handleToggleEnabled(newV) {
        setEnabled(newV)
        if (newV) setMaxFlaggedTimestamp(internalMax)
        else setMaxFlaggedTimestamp(null)
    }
    function handleChange(value) {
        setInternalMax(value)
        setMaxFlaggedTimestamp(value)
    }
    return (
        <div className='w-fit'>
            <JCheckbox
                className='pr-3 pl-4 py-1 h-10 bg-background'
                handleChange={handleToggleEnabled} 
                value={enabled}
                label='Hide agencies flagged within'
            >
                    <Select
                        valueIsID
                        buttonClassName="w-[8rem] h-7 text-sm"
                        popupClassName="w-[8rem]"
                        options={selectOptions}
                        value={maxFlaggedTimestamp}
                        handleChange={({value}) => handleChange(value)}
                        renderFn={(opt) => opt.label}
                        IDFn={(opt) => opt.value}
                        inForm={false}
                        disabled={!enabled}
                        showSearch={false}
                        placeholder='30 days ago'
                    />
            </JCheckbox>
        </div>
    );
};
