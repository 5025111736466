import { AgencyReport } from "@/routes/Main/Reporting/Presets/UsageReport/components/AgencyReport";
import { AgencyReportContent } from "@/routes/Main/Reporting/Presets/UsageReport/components/AgencyReportContent";
import { BranchReport } from "@/routes/Main/Reporting/Presets/UsageReport/components/BranchReport";
import { BranchReportContent } from "@/routes/Main/Reporting/Presets/UsageReport/components/BranchReportContent";
import { NationalReport } from "@/routes/Main/Reporting/Presets/UsageReport/components/NationalReport";
import { NationalReportContent } from "@/routes/Main/Reporting/Presets/UsageReport/components/NationalReportContent";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import useAllBranches from "../../../../../api/branches/getAllBranches";
import useMyUserData from "../../../../../api/user/getMyUserData";
import { useMyAuthorizedAgencies } from "../../../../../hooks/state/useMyAuthorizedAgencies";
import { useMyAuthorizedBranches } from "../../../../../hooks/state/useMyAuthorizedBranches";
import { useObjectSearchParams } from "../../../../../hooks/state/useObjectSearchParams";
import { useSyncYupValidationResolver } from "../../../../../hooks/useYupValidationResolver";
import Yup from "../../../../../wrappers/yup";
import { UsageReportSettings } from "./UsageReportSettings";
import { useUsageReportData } from "./useUsageReportData";

export type UsageReportConfig = {
    view?: UsageReportView;
    branch?: any;
    agency?: any;
    scope?: {
        active?: boolean;
        inactive?: boolean;
    };
    separateBranches?: boolean;
    separateAgencies?: boolean;
};

export type UsageReportView = "national" | "branch" | "agency";

const settingsSchema = Yup.object({
    view: Yup.string().oneOf(["national", "branch", "agency"]).required(),
    branch: Yup.object({
        id: Yup.string().required(),
        name: Yup.string().required(),
    }).when(["view"], {
        is: "branch",
        then: (schema) => schema.required("oops"),
        otherwise: (schema) => schema.nullable(),
    }),
    agency: Yup.object({
        id: Yup.string().required(),
        name: Yup.string().required(),
    }).when(["view"], {
        is: "agency",
        then: (schema) => schema.required("oops"),
        otherwise: (schema) => schema.nullable(),
    }),
    separateBranches: Yup.boolean().when(["view"], {
        is: (view) => view === "national",
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
    }),
    separateAgencies: Yup.boolean().when(["view", "separateBranches"], {
        is: (view, separateBranches) =>
            (view === "national" && separateBranches) || view === "branch",
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
    }),
});

export const UsageReport = () => {
    const settingsContainerRef = useRef(null);
    const [config, setConfig] = useObjectSearchParams();
    const [activeStep, setActiveStep] = useState(1);
    const [configValid, setConfigValid] = useState(false);
    const [shouldGenerateReport, setShouldGenerateReport] = useState(false);
    const resolver = useSyncYupValidationResolver(settingsSchema);

    const myUserDataQuery = useMyUserData();
    const allBranchesQuery = useAllBranches();

    const branchesQuery = useMyAuthorizedBranches({
        myUserDataQuery,
        allBranchesQuery,
    });
    const agenciesQuery = useMyAuthorizedAgencies({
        myUserDataQuery,
        allBranchesQuery,
    });

    // const searchParamObj = useMemo(() => deserializeSP(searchParams),[searchParams])

    useLayoutEffect(validateConfig, [config]);
    const errors = useMemo(getConfigErrors, [config]);
    const reportData = useUsageReportData({
        config: config,
        branchesQuery,
        agenciesQuery,
        myUserDataQuery,
        enabled: configValid,
    });
    function getConfigErrors() {
        return resolver(config).errors;
    }

    function validateConfig() {
        const { view, branch, agency, scope } = config;
        const { errors } = resolver(config);
        setShouldGenerateReport(false);
        if (Object.keys(errors).length > 0) setConfigValid(false);

        if (errors.view) return setActiveStep(1);
        if (view === "national") {
            if (errors.separateBranches) return setActiveStep(2);
            if (errors.separateAgencies) return setActiveStep(3);
            setActiveStep(3);
            setConfigValid(true);
        }
        if (view === "branch") {
            if (errors["branch.id"] || errors["branch.Name"])
                return setActiveStep(2);
            if (errors.separateAgencies) return setActiveStep(3);
            setActiveStep(3);
            setConfigValid(true);
        }
        if (view === "agency") {
            if (errors["agency.id"] || errors["agency.Name"])
                return setActiveStep(2);
            setActiveStep(2);
            setConfigValid(true);
        }
    }
    function scrollToTop() {
        (settingsContainerRef.current as HTMLDivElement).scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
        });
    }
    useEffect(() => {
        if (shouldGenerateReport) return;
        scrollToTop();
    }, [shouldGenerateReport]);

    return (
        <div>
            <div className="flex flex-col gap-4">
                <div ref={settingsContainerRef}>
                    <UsageReportSettings
                        {...{
                            myUserData: myUserDataQuery.data,
                            config: config as UsageReportConfig,
                            setConfig: setConfig as (
                                prev: UsageReportConfig
                            ) => UsageReportConfig,
                            errors,
                            setConfigValid,
                            configValid,
                            activeStep,
                            setActiveStep,
                            branches: branchesQuery.data || [],
                            agencies: agenciesQuery.data || [],
                            shouldGenerateReport,
                            setShouldGenerateReport,
                        }}
                    />
                </div>
                <AnimatePresence presenceAffectsLayout mode="wait">
                    {shouldGenerateReport && (
                        <motion.div
                            transition={{ duration: 0.7 }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            {
                                {
                                    national: (
                                        <NationalReport
                                            NationalReportComponent={
                                                NationalReportContent
                                            }
                                            AgencyReportComponent={
                                                AgencyReportContent
                                            }
                                            BranchReportComponent={
                                                BranchReportContent
                                            }
                                            {...reportData}
                                            config={config}
                                        />
                                    ),
                                    branch: (
                                        <BranchReport
                                            AgencyReportComponent={
                                                AgencyReportContent
                                            }
                                            BranchReportComponent={
                                                BranchReportContent
                                            }
                                            {...reportData}
                                            config={config}
                                        />
                                    ),
                                    agency: (
                                        <AgencyReport
                                            AgencyReportComponent={
                                                AgencyReportContent
                                            }
                                            {...reportData}
                                            config={config}
                                        />
                                    ),
                                }[config.view]
                            }
                            {/* {config.view === "national"} */}
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
};
UsageReport.displayName = "UsageReport";
