import useAllAgencies from "@/api/agencies/getAllAgencies";
import useAllBranches, {
    getAllBranchesQueryOptions,
} from "@/api/branches/getAllBranches";
import useCreateUser from "@/api/user/createUser";
import useDeleteUser from "@/api/user/deleteUser";
import useBranchAdmins, {
    getBranchAdminsQueryOptions,
} from "@/api/user/getBranchAdmins";
import useMyUserData, { getMyUserDataQueryOptions } from "@/api/user/getMyUserData";
import useModBranchAdmin from "@/api/user/modBranchAdmin";
import { useSendSignupInvite } from "@/api/user/sendSignupInvite";

import { PageHeader } from "@/components/misc/PageHeader";
import { Table } from "@/components/tables/Table";
import { branchAdminsColumnHelper } from "@/components/tables/columnHelpers/branchAdminsColumnHelper";
import { Button } from "@/components/ui/button";
import { useMyAuthorizedAgencies } from "@/hooks/state/useMyAuthorizedAgencies";
import { useMyAuthorizedBranches } from "@/hooks/state/useMyAuthorizedBranches";
import { AllAgencies } from "@/routes/Main/Admin/AllAgencies";
import { EditBranchUserDialog } from "@/routes/Main/Admin/Users/components/EditBranchUserDialog";
import { InviteUserDialog } from "@/routes/Main/Admin/Users/components/InviteUserDialog";
import { useAlert } from "@/wrappers/alerts/useAlert";
import { useQueryResultToasts } from "@/wrappers/toasts";
import { MailPlus, UserPlusIcon } from "lucide-react";
import React, { useMemo } from "react";

export const Users = ({ ...props }) => {
    const [openUserDialog, setOpenUserDialog] = React.useState(false);
    const [openInviteDialog, setOpenInviteDialog] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [creatingAgencyUser, setCreatingAgencyUser] = React.useState(true);
    const myDataQuery = useMyUserData() 
    const queryResultToasts = useQueryResultToasts();
    const modBranchAdminMutation = useModBranchAdmin(
        queryResultToasts(
            "Updated user.",
            "Failed to update user.",
            () => {},
            () => console.log("fail")
        )
    );
    const deleteBranchAdminMutation = useDeleteUser(
        queryResultToasts("Deleted user.", "Failed to delete user.")
    );
    const createUserMutation = useCreateUser(
        queryResultToasts("Created user.", "Failed to create user.")
    );
    const inviteUserMutation = useSendSignupInvite(
        queryResultToasts("Invited user.", "Failed to invite user.")
    )
    const usersQuery = useBranchAdmins();
    const d = useAllAgencies()
     
    const allBranchesQuery = useAllBranches()
    const authrBranchesQuery = useMyAuthorizedBranches({
        allBranchesQuery,
        myUserDataQuery: myDataQuery
    });
    const agenciesQuery = useMyAuthorizedAgencies({
        allBranchesQuery: authrBranchesQuery,
        myUserDataQuery: myDataQuery
    })
    const agencyIDsInMyDomain = useMemo(() => {
        if (!agenciesQuery.data || !myDataQuery.data) return [];
        if (myDataQuery.data?.isAdmin) {
            return agenciesQuery.data.map((agency) => agency.id);
        }
        else {
            if (!agenciesQuery.data) return [];
            return agenciesQuery.data
                ?.filter((agency) => myDataQuery.data?.nodeids.includes(agency.branchID))
                .map((agency) => agency.id);
        }
    }, [myDataQuery.data, agenciesQuery.data]);

    const creatableUserTypes = useMemo(() => {
        if (!myDataQuery.data) return ['agency'];
        return myDataQuery.data.isAdmin 
            ? ["branch", "agency", "admin"]
            : ["agency"];
    },[myDataQuery.data])

    const existingEmails = useMemo(() => {
        if (!usersQuery.data) return []
        return usersQuery.data?.map((u) => u.email)
    },[usersQuery.data])

    const { alert } = useAlert();
    
    function deleteUserClicked(row) {
        alert({
            title: "Delete User",
            message: () => (
                <div>
                    Are you sure you want to delete user{" "}
                    <span className="font-medium">
                        {row.firstname} {row.lastname}
                    </span>
                    ?
                </div>
            ),
            status: "warning",
            showConfirm: true,
            showCancel: true,
        }).then((res) => {
            console.log("deleting user", res);
            res.confirmed &&
                deleteBranchAdminMutation.mutate({ targetid: row.id });
        });
    }

    function modUserClicked(row) {
        setSelectedUser(row);
        setOpenUserDialog(true);
    }
    function createUserClicked() {
        setSelectedUser(null);
        setOpenUserDialog(true);
    }
    function userFormSubmitted(fields) {
        // console.log("selected user on submit", selectedUser);
        const { confirmPassword, isEdit, ...params } = fields;
        setOpenUserDialog(false);
        const finalParams = {
            ...params,
        };
        // console.log("final user creation params", finalParams);
        selectedUser
            ? modBranchAdminMutation.mutate({
                  targetid: selectedUser.id,
                  ...finalParams,
              })
            : createUserMutation.mutate(finalParams);
    }
    function handleUserDialogAction(opening) {
        // console.log("changing open state");
        !opening && setSelectedUser(null);
        setOpenUserDialog(opening);
    }
    function inviteUserClicked() {
        setOpenInviteDialog(true);
    }
    function inviteFormSubmitted(fields) {
        // console.log("invite form submitted", fields);
        setOpenInviteDialog(false);
        inviteUserMutation.mutate(fields);
    }

    return (
        <div className="flex flex-col h-full">
            <PageHeader title="User Management"></PageHeader>
            <Table
                exportFileName="users"
                data={usersQuery.data}
                loading={usersQuery.isPending}
                columnHelper={branchAdminsColumnHelper}
                maxHeightPixels={"100%"}
                meta={{
                    deleteUser: deleteUserClicked,
                    modUser: modUserClicked,
                    branchesQueryData: allBranchesQuery.data,
                    agenciesQueryData: d.data,
                    agencyIDsInMyDomain,
                    isAdmin: myDataQuery.data?.isAdmin,
                }}
            >
                <Button onClick={createUserClicked} variant={"default"}>
                    Add User <UserPlusIcon className="ml-2 w-5 h-5" />
                </Button>
                <Button onClick={inviteUserClicked} variant={"default"}>
                    Invite User <MailPlus className="ml-2 w-5 h-5" />
                </Button>
            </Table>
            <EditBranchUserDialog
                branchesQuery={authrBranchesQuery}
                agenciesQuery={agenciesQuery}
                existingEmails={existingEmails}
                creatingAgencyUser={creatingAgencyUser}
                setCreatingAgencyUser={setCreatingAgencyUser}
                onSubmit={userFormSubmitted}
                open={openUserDialog}
                onOpenChange={handleUserDialogAction}
                isEdit={selectedUser !== null}
                editData={selectedUser}
                loading={createUserMutation.isPending}
                creatableUserTypes={creatableUserTypes}
            />
            <InviteUserDialog
                branchesQuery={authrBranchesQuery}
                agenciesQuery={agenciesQuery}
                existingEmails={existingEmails}
                open={openInviteDialog}
                onOpenChange={setOpenInviteDialog}
                loading={inviteUserMutation.isPending}
                onSubmit={inviteFormSubmitted}
                creatableUserTypes={creatableUserTypes}
            />

        </div>
    );
};

export const UsersLoader = (queryClient) => async () => {
    try {
        const myData = await queryClient.ensureQueryData({
            queryKey: getMyUserDataQueryOptions.queryKey,
        });
        const userLevel = Number(myData?.adminLevel);
        const branchesData = queryClient.ensureQueryData({
            queryKey: getAllBranchesQueryOptions(queryClient).queryKey,
        });
        const branchAdminsData = queryClient.ensureQueryData({
            queryKey: getBranchAdminsQueryOptions.queryKey,
        });
        return { branchAdminsData, branchesData };
    } catch (error) {}
};
