import { getAgenciesInBranchQueryOptions } from '@/api/agencies/getAgenciesInBranch';
import useAllBranches from '@/api/branches/getAllBranches';
import { postPromise } from '@/api/promises';
import { ROUTE_IDS } from '@/api/routeIDs';
import { composeFormData } from "@/util/apiUtils";
import { queryOptions, useQueries, useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

export const getAllAgencies = ({queryKey}) => {
    const {_key, params} = queryKey;
	return postPromise(import.meta.env.VITE_API_BASE_URL,composeFormData(params, ROUTE_IDS.GET_ALL_AGENCIES));
}

export default function useAllAgencies() {
    const branchesQuery = useAllBranches()
    // console.log(branchesQuery.data, (branchesQuery.data || [])?.map(b => b.id));
    const query = useQueries({
        queries: (branchesQuery.data || [])?.map(b => getAgenciesInBranchQueryOptions(b.id)),
        combine: useCallback((queries) => {
            const isPending= queries.some(d => d.isPending)
            if (isPending) return {data:null,isPending}
            const flat = queries.map((q, idx) => {
                for (const agency of q.data) {
                    agency.branch = branchesQuery.data[idx]?.Name
                    agency.branchName = branchesQuery.data[idx]?.Name
                    agency.branchID = branchesQuery.data[idx]?.id
                }
                return q.data
            }).flat()
            return {
                data: flat,
                isPending,
            }
        },[branchesQuery.data]) 
    });
    return query;
}
export const getAllAgenciesQueryOptions = (branchids) => queryOptions({
    queryKey: [ROUTE_IDS.GET_ALL_AGENCIES, ],
    staleTime: 1000 * 60 * 5,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: branchids && branchids.length > 0,
    queryFn: () => getAllAgencies(),})

