import { JCheckbox } from '@/wrappers/ui/JCheckbox';
import React, { useState } from 'react';

export const AgencyActiveFilterControls = ({ hideActive, setHideActive}) => {
    function handleToggleHide() {
        setHideActive((prev) => !prev)
    }
    return (
        <JCheckbox 
            className='pr-3 pl-4 py-1 h-10 bg-background'
            value={hideActive}
            handleChange={handleToggleHide}
            label="Hide inactive"
        />
    );
};
